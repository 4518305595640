import '../../dialogs.scss';
import './sessionInfoDialog.scss';

import ClassNames from 'classnames';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Strings from "../../../../models/store/strings";
import { IAddedUserSearchItem, ITabsItem, InputSearch, Modal, Switcher, Tabs, UserSearch } from '../../../../components';
import Credits from '../../../../components/credits/credits';
import PlayerTooltip from '../../../../components/tooltips/playerTooltip';
import { Images } from '../../../../constants';
import { ActM, PolM, SrvM, StM } from '../../../../modules';
import UserList from '../../bookingUserList';
import { SessionPrices } from '../sessionPrices';
import { SessionAdditionalServices } from '../sessionServices';

let utils = new SrvM.Utils();
let render = new SrvM.SessionInfoDialogRenderService();

let isOpen: boolean = false;
let isOpenPlayerList: boolean = false;
let isOpenShareList: boolean = false;
let firstOpen: boolean = false;

interface ISessionInfoDialogProps {
    match: any;
    isShown: boolean;
    isAuthorized: boolean;
    from: string;
    id: any;
    inviteToken: string;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    session: StM.ISessionStoreState;
    sessions?: Array<StM.ISessionStoreState>;
    notification: StM.INotificationStoreState;
    users: Array<StM.IPublicUserStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    addons: Array<StM.IAddonDefinitionStoreState>;
    maxPlayerCount: number;
    basketSessions: Array<StM.ISessionStoreState>;
    basketCopy: StM.ISessionStoreState;
    originCopy: StM.ISessionStoreState;
    agreeWithCancelationFee: boolean;

    showSpinner: () => any;
    hideSpinner: () => any;
    editBasketItem: (item: StM.ISessionStoreState) => void;
    addSessionToBasket: (item: StM.ISessionStoreState) => void;
    addSessionToBasketSilently: (item: StM.SessionStoreState) => void;
    rejectInvitation: (sessionId: number, params: any) => Promise<void>;
    saveSession: (session: StM.ISessionStoreState) => Promise<StM.ISessionStoreState>;
    saveInvitedUsers: (users: Array<StM.IPublicUserStoreState>, sessionId: number, token: string) => Promise<any>;
    dropOutSession: (session: StM.ISessionStoreState, user: StM.IUserStoreState, agreeWithCancelationFee: boolean) => Promise<StM.ISessionStoreState>;
    cancelSession: (session: StM.ISessionStoreState, agreeWithCancelationFee: boolean) => Promise<StM.ISessionStoreState>;
    getSessionById: (id: number, skipDialogUpdating?: boolean) => Promise<StM.ISessionStoreState>;
    getSessionByInviteToken: (inviteToken: string) => Promise<StM.ISessionStoreState>;
    acceptSessionInviteByInviteToken: (inviteToken: string) => Promise<StM.ISessionStoreState>;
    rejectSessionInviteByInviteToken: (inviteToken: string) => Promise<StM.ISessionStoreState>;
    getSessionInviteToken: (sessionId: number) => Promise<any>;
    getSessionInviteLink: (inviteToken: string, session: StM.ISessionStoreState) => string;
    getFBSessionInviteLink: (inviteToken: string) => string;
    getSessionVideoLink: (token: string) => string;
    updateCurrentPageSessions: (params: any) => Promise<StM.ISessionStoreState>;
    sendNotification: (notification: StM.INotificationStoreState) => void;
    showAlert: (msgKey: string, messageType?: string, message?: string) => Promise<any>;
    showCancelConfirmation: (msgKey: string, messageType?: string, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => Promise<any>;
    showAlertCancelSession: (msgKey: string) => Promise<any>;
    showAlertDropOutSession: (msgKey: string) => Promise<any>;
    showSuccessInvitationAcceptPrompt: () => Promise<any>;
    showErrorInvitationAcceptPrompt: (message: string) => Promise<any>;
    showRejectInvitationPrompt: () => Promise<any>;
    removeBasketSession: (item: StM.SessionStoreState) => void;
    getNotification: (sessionId: number) => Promise<any>;
    removeNotificationsBySession: (id: number) => void;
    openAuthDialog: (url: string) => Promise<any>;
    openBillingInfoDialog: () => Promise<any>;
    closeAlert: () => void;
    closeSessionInfoDialog: () => void;
    dropOutUsers: (session: StM.ISessionStoreState, users: StM.IPublicUserStoreState[]) => Promise<StM.ISessionStoreState>;
    checkout: (session: StM.ISessionStoreState, params: any, updateBasket: boolean) => Promise<any>;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
}

interface ISessionInfoDialogState {
    shareBoard?: boolean;
    players?: Array<StM.IPublicUserStoreState>;
    invitedUsers?: Array<StM.IPublicUserStoreState>;
    declinedUsers?: Array<StM.IPublicUserStoreState>;
    isShared?: boolean;
    playersNumber?: number;
    isPayCredits?: boolean;
    isPayCreditsSelectionUpdated?: boolean;
    isAddedUsersUpdated?: boolean;
    errors?: any;
    services?: StM.IAddonDefinitionStoreState[];
}

class SessionInfoDialog extends React.Component<ISessionInfoDialogProps, ISessionInfoDialogState> {
    private shareLinkDOM: any = null;
    private save: StM.ISessionStoreState;
    private sessionAvailabilityPolicy: PolM.SessionAvailabilityPolicy;
    private sessionInfoPolicy: PolM.SessionInfoPolicy;
    private authSrv = new SrvM.AuthenticationService();
    private groupInfoPolicy: PolM.GroupInfoPolicy;
    private minPlayersCount = 1;

    private tabs: ITabsItem[] = [
        {
            name: 'players',
            title: 'Players',
            render: () => this.renderPlayersTab()
        }, {
            name: 'seeking',
            title: 'Privacy',
            render: () => this.renderSeekingPlayerTab(this.save),
            getIsShow: () => this.getIsShowSeekingPlayerTab()
        }, {
            name: 'share',
            title: 'Share link',
            render: () => this.renderShareLinkTab(),
            getIsShow: () => this.getIsShowShareTab()
        }
    ];

    clinicTabs: Array<any> = [
        {
            name: 'players',
            title: 'Players',
            render: () => this.renderPlayersTab()
        }, {
            name: 'share',
            title: 'Share link',
            render: () => this.renderShareLinkTab(),
            getIsShow: () => this.getIsShowShareTab()
        }
    ];

    constructor(props: ISessionInfoDialogProps) {
        super(props);
        this.save = null;
        this.sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(null, []);
        this.sessionInfoPolicy = new PolM.SessionInfoPolicy(null);
        this.groupInfoPolicy = new PolM.GroupInfoPolicy(null);
        this.state = this.getStateFromProp(props, {});
    }

    UNSAFE_componentWillReceiveProps(newProps: ISessionInfoDialogProps) {
        if (!newProps.isShown && this.props.isShown) {
            this.reset();
        } else {
            const newState = this.getStateFromProp(newProps, this.state);

            this.setState(newState);
        }
    }

    
    shouldComponentUpdate(nextProps: ISessionInfoDialogProps, nextState: ISessionInfoDialogState): boolean {
        const isAuthorizedChanged = nextProps.isAuthorized && !this.props.isAuthorized;
        const isShowChanged = nextProps.isShown && !this.props.isShown;
        const isIdChanged = nextProps.id != this.props.id;
        const isFromChanged = nextProps.from !== this.props.from;
        const isSessionChanged = !_.isEqual(nextProps.session, this.props.session) || this.save == null;
        const isFromBasket = nextProps.from == StM.SessionInfoFromTypes.basket;
        if (isShowChanged || (isIdChanged && (nextProps.from != StM.SessionInfoFromTypes.inviteLink && nextProps.from != StM.SessionInfoFromTypes.inviteAcceptLink && nextProps.from != StM.SessionInfoFromTypes.inviteRejectLink))) {
            this.initSession(nextProps.id, nextProps.from);
            if (!isFromBasket) return false;
        }
        if (nextProps.isShown && (isSessionChanged || isAuthorizedChanged)) {
            if (isAuthorizedChanged && (nextProps.from == StM.SessionInfoFromTypes.inviteLink || nextProps.from == StM.SessionInfoFromTypes.inviteAcceptLink || nextProps.from == StM.SessionInfoFromTypes.inviteRejectLink)) this.initSession(nextProps.inviteToken, nextProps.from);
            this.sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(nextProps.session, nextProps.basketSessions, nextProps.user);
            this.sessionInfoPolicy = new PolM.SessionInfoPolicy(nextProps.originCopy, nextProps.user);
            this.groupInfoPolicy = new PolM.GroupInfoPolicy(nextProps.user);
            this.save = nextProps.session;
            this.ensureSharedFields();
        }
        return true;
    }

    render() {
        const session = this.getSessionFromState();
        if (this.props.isShown && session && this.save) {
            let user = this.props.user;
            
            firstOpen = false;

            const classes = ClassNames("modal-session-info", utils.getSessionClass(session));
            let styles: any = {};
            if (session.type === StM.SessionType.Custom) {
                styles = {
                    borderTopColor: session.customBackgroundColor
                }
            }
            return (
                <Modal classes={classes} closeHandler={this.props.closeSessionInfoDialog} dialogName={StM.DialogNames.SessionInfo} style={styles}>
                    <div className="modal-dialog">
                        {this.renderHeader(session, user)}
                        {this.renderBody(session)}
                        <SessionAdditionalServices activeServices={this.state.services} onChange={this.handleServicesChange} session={session} />
                        {this.renderFooter(session, user)}
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }

    private renderHeader(session: StM.SessionStoreState, user: StM.IUserStoreState) {
        return (
            <div className="modal-dialog-header header-info">
                <span className="close-dialog" onClick={(e: any) => this.closeDialogClick(e)}></span>
                <div className="modal-dialog-title">
                    {this.getSessionTitle(session, user)}
                </div>
                <div className="session-info">
                    {this.renderSlots(session)}
                    {this.renderTextSlot(session)}
                    {this.renderSkillLvl(session)}
                </div>
                <div className="divider-line-modal"></div>
            </div>
        );
    }

    private renderBody(session: StM.SessionStoreState) {
        const prices = this.getPrices();
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const hasCredits = !!prices.credits || !!prices.additionalCredits;
        const isInviteLink = this.props.from == StM.SessionInfoFromTypes.inviteLink;
        const showPaymentMethodSelection = !session.splitPrice 
            && (this.sessionAvailabilityPolicy.handle() || isInviteLink)
            && (this.isGroup() || !this.sessionAvailabilityPolicy.getIsBoughtUser())
            && !_.some(session.bookings, (b) => b.userId == this.props.user.id && (b.status == StM.BookingStatus.LateCancel || b.status == StM.BookingStatus.LateCancelPayFail))
            && hasCredits
            && this.sessionAvailabilityPolicy.getIsFuture()
            && this.sessionInfoPolicy.getPlayers(isGroupSession).length < session.maxUserCount
            && !session.isPaidByOwner
            && !isGroupSession;
        return (
            <div className="modal-dialog-body">
                <div className="info-wrapper">
                    {this.renderDateBlock(session)}
                    {this.renderCoach(session)}
                    {this.renderCourts(session)}
                    {this.renderAge(session)}
                    {this.renderDescription(session)}
                    {this.renderSessionPlayers(session)}
                    {showPaymentMethodSelection && this.renderPaymentSelection()}
                    {this.renderInvitationSection(session)}
                </div>
            </div>
        );
    }

    private renderPrice(session: StM.ISessionStoreState) {
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const isGroupMemberSession = this.sessionAvailabilityPolicy.getIsGroupMemberSession();
        if (this.getIsInvited() || (isGroupMemberSession && !isGroupSession)) return null;
    
        return (
            <SessionPrices className='session-prices-wrapper' session={this.getSessionFromState()} />
        );
    }

    private renderPaymentSelection() {
        return (<div className="modal-dialog-sub-body">
            <div className="payment-wrapper">
                <div className="payment-method-wrapper">
                    <div className="title">Payment Method</div>
                    <div className="radio-wrapper">
                        <div className="radio-item">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input"
                                    name="payment-method" value="option1"
                                    checked={!this.state.isPayCredits} hidden onChange={(e) => this.payCashRadioHandler(e)} />
                                <span className="form-check-text">Charge Card</span>
                            </label>
                        </div>
                        <div className="radio-item club-credits">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input"
                                    name="payment-method" value="option2" hidden
                                    checked={this.state.isPayCredits} onChange={(e) => this.payCreditsRadioHandler(e)} />
                                <span className="form-check-text">Club Credits</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    private renderFooter(session: StM.SessionStoreState, user: StM.IUserStoreState) {
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const isGroupMemberSession = this.sessionAvailabilityPolicy.getIsGroupMemberSession();
        const isFuture = this.sessionAvailabilityPolicy.getIsFuture();
        const isClosed = session.status === StM.SessionStatus.Closed;
        const isTrainer = this.sessionAvailabilityPolicy.getIsTrainer();
        if (isTrainer || (isGroupMemberSession && !isGroupSession && !this.state.shareBoard)) return null;
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isOwnerGroupMember =  this.sessionAvailabilityPolicy.getIsOwnerGroupMember();
        const isInviteLink = this.props.from == StM.SessionInfoFromTypes.inviteLink;
        const isShowInviteFooter = ((this.isGroup() && (!isGroupSession || !isOwnerGroupMember)) || !isBought)
            && (this.sessionAvailabilityPolicy.handle() || isInviteLink) && (!this.isGroup() || this.state.shareBoard);
        return (
            <>
                {(!isShowInviteFooter || !isFuture || isClosed) && this.renderPrice(session)}
                {(isFuture && !isClosed) && (
                    <div className="modal-dialog-footer">
                        {isShowInviteFooter ? this.renderInvitedFooter(session) : this.renderPlayerFooter(session, user)}
                    </div>
                )}
            </>
        );
    }

    private initSession(id: any, from: string): Promise<any> {
        if (!id) return Promise.resolve();
        let prom: Promise<any> = null;
        const sessionProcess = (session: StM.ISessionStoreState) => {
            if (!session) {
                this.props.closeSessionInfoDialog();
                this.props.removeNotificationsBySession(+id);
                this.props.showAlert(StM.MessagesKey.SessionNotFound);
                this.props.updateCurrentPageSessions(this.props.match.params);
            }
            else if(from == StM.SessionInfoFromTypes.inviteAcceptLink) {
                if(session.validationResult && session.validationResult.errors && session.validationResult.errors.length) {
                    const error = session.validationResult.errors[0];
                    if(error.type == 'billing'){
                        this.props.closeSessionInfoDialog();
                        this.props.addSessionToBasketSilently(session);
                        if(this.props.isAuthorized) this.props.openBillingInfoDialog();
                        else {
                            let hash = new SrvM.RouteDialogService().getDialogHash(StM.DialogNames.BillingInfo, {});
                            this.props.openAuthDialog(encodeURIComponent(hash));
                        }
                    }
                    this.props.showErrorInvitationAcceptPrompt(error.message);             
                }
                else {
                    this.props.showSuccessInvitationAcceptPrompt();
                    this.props.updateCurrentPageSessions(this.props.match.params);
                }
            }
            else if(from == StM.SessionInfoFromTypes.inviteRejectLink) {
                this.props.closeSessionInfoDialog();
                this.props.showRejectInvitationPrompt();
                this.props.updateCurrentPageSessions(this.props.match.params);
            }
            return session;
        };

        if (from == StM.SessionInfoFromTypes.inviteLink) {
            prom = this.props.getSessionByInviteToken(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                });
        }
        else if (from == StM.SessionInfoFromTypes.inviteAcceptLink) {
            prom = this.props.acceptSessionInviteByInviteToken(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                }).
                catch((error)=> {
                    console.error(error);
                    this.props.closeSessionInfoDialog();
                    this.props.showAlert(StM.MessagesKey.SessionNotFound);
                    if(this.props.isAuthorized) this.props.updateCurrentPageSessions(this.props.match.params); 
                });
        }
        else if (from == StM.SessionInfoFromTypes.inviteRejectLink) {
            prom = this.props.rejectSessionInviteByInviteToken(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                });
        }
        else if (from !== StM.SessionInfoFromTypes.basket) {
            prom = this.props.getSessionById(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                });

        }

        if (prom) {
            this.props.showSpinner();
            return prom
                .then((session: StM.ISessionStoreState) => {

                    this.props.hideSpinner();
                }).catch(() => {
                    this.props.hideSpinner();
                });
        }
        return Promise.resolve();
    }

    private getStateFromProp(props: ISessionInfoDialogProps, state: ISessionInfoDialogState): ISessionInfoDialogState {
        const session = props.basketCopy || props.session;
        if(!session) return null;
        const sessionInfo = new PolM.SessionInfoPolicy(session);
        const pricesPolicy = new PolM.PricesPolicy(props.session.isDoubledSession, props.session);
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(props.session, props.basketSessions, props.user);
        const notActiveBooking = props.user ? session.bookings.find(b => b.userId === props.user.id && !utils.isActiveBooking(session, b)) : null;
        const isNotActiveBookingChecked = !notActiveBooking || notActiveBooking.paymentType === StM.PaymentTypes.Credits;
        const isOwner = sessionAvailabilityPolicy.getIsOwner();
        const prices = pricesPolicy.handle();
        const hasFreeSlots = this.sessionAvailabilityPolicy.getIsHaveFreePlaces();
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isPayCredits = props.basketCopy 
            ? !!props.basketCopy.credits 
            : !!(!!prices.credits && (isOwner || isBought || hasFreeSlots || this.isGroup()) && isNotActiveBookingChecked);
        const isShared = this.getIsShared(session);
        const isGroupSession = sessionAvailabilityPolicy.getIsGroupSession();
        return {
            invitedUsers: sessionInfo.getInvitedUsers(),
            declinedUsers: sessionInfo.getDeclinedUsers(),
            players: sessionInfo.getPlayers(),
            shareBoard: this.sessionAvailabilityPolicy.getIsCanInvitePlayers(),
            isShared,
            playersNumber: session ? session.maxUserCount : 0,
            isPayCredits,
            services: utils.getSessionServices(session, !isGroupSession && props.user)
        }
    }

    private getIsShared(session: StM.ISessionStoreState): boolean {
        return session && !session.isHidden;
    }

    private getIsInvited(): boolean {
        const isInvited = this.sessionAvailabilityPolicy.getIsInvited();
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isInviteLink = this.props.from == StM.SessionInfoFromTypes.inviteLink;
        return (isInvited || isInviteLink) && !isBought;
    }

    private reset() {
        this.save = null;
        this.setState({
            shareBoard: false,
            isPayCreditsSelectionUpdated: false,
            isAddedUsersUpdated: false,
            services: [],
        })
    }
    // handlers

    private closeDialogClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeSessionInfoDialog();
    }

    private ensureSharedFields() {
        if (!this.save) return;
        this.save.playerQualification = this.save.playerQualification
            ? { ...this.save.playerQualification }
            : new StM.PlayerQualificationStoreState();
        this.save.seeking = this.save.seeking
            ? { ...this.save.seeking }
            : new StM.SeekingStoreState();
    }

    private dropOutClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.showConfirmationDialog(StM.MessagesKey.DropOutConfirmation, this.dropOutSessionAction.bind(this));
    }

    private dropOutSessionAction (agreeWithCancelationFee: boolean) {
        this.props.showSpinner();
        this.props.dropOutSession(this.props.session, this.props.user, agreeWithCancelationFee)
            .then(() => {
                return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
            }).then(() => {
                this.props.closeSessionInfoDialog();
                this.props.showAlertDropOutSession(StM.MessagesKey.DropOutSession);
                this.props.hideSpinner();
            }).catch((error: any) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                    const message: string = error.response.data.exceptionMessage;
                    if (message.indexOf('Session contains player bookings') > -1) { //'Session contains player bookings that cannot be cancelled.'
                        this.props.showAlert(StM.MessagesKey.UnableCheckedInDropOutSession);
                    }
                }
            });
    }

    private cancelClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.showConfirmationDialog(StM.MessagesKey.CancelConfirmation, this.cancelSessionAction.bind(this));
    }

    private cancelSessionAction (agreeWithCancelationFee: boolean) {
        this.props.showSpinner();
        this.props.cancelSession(this.props.session, agreeWithCancelationFee)
        .then(() => {
            return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
        }).then(() => {
            this.props.closeSessionInfoDialog();
            this.props.showAlertCancelSession(StM.MessagesKey.CancelSession);
            this.props.hideSpinner();
        }).catch((error: any) => {
            this.props.hideSpinner();
            if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                const message: string = error.response.data.exceptionMessage;
                if (message.indexOf('Unable to cancel session') > -1) { //'Unable to cancel session when at least one user is checked in'
                    this.props.showAlert(StM.MessagesKey.UnableCheckedInCancelSession);
                }
            }
        });
    }
    
    private showConfirmationDialog(messageKey: string, acceptAction: (agreeWithCancelationFee: boolean) => void) {
        const minimalCancellationPeriod = this.props.club.minimalCancellationPeriod;
        const sessionStartDateTime = this.props.session.startDateTime.toDate();
        const isFeeInPercent = this.props.club.isLateCancellationFeeInPercent;
        const lateCancellationFee =
            isFeeInPercent
            ? this.props.club.lateCancellationFee === 100
                ? "full price"
                : this.props.club.lateCancellationFee + '%25 of the price'
            : this.props.club.lateCancellationFee + '$';

        const message = `If you proceed, you'll be charged ${lateCancellationFee} as you're within ${minimalCancellationPeriod} hours of your session time.`; 
        const acceptButtonText = "Yes, I`m sure";
        const rejectButtonText = "No, I`ll keep my session";
        
        const isCanceledWithinPeriod = utils.isCanceledWithinPeriod(
            sessionStartDateTime, 
            minimalCancellationPeriod, 
            this.props.club.aliasedTimeZone.dbName
        );
        if (isCanceledWithinPeriod) {
            this.props.showCancelConfirmation(
                messageKey,
                StM.MessageTypes.Warning, 
                message,
                acceptButtonText,
                rejectButtonText,
                () => acceptAction(isCanceledWithinPeriod)
                );
        } else {
            acceptAction(isCanceledWithinPeriod);
        }
    }

    private setMaxPlayersClick(e: any, max: number) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.save.maxUserCount = max;

        if (max < this.save.invitedUsers.length + 1) {
            const deleteCount = this.save.invitedUsers.length + 1 - max;
            const startIndex = this.save.invitedUsers.length - deleteCount;
            this.save.invitedUsers.splice(startIndex, deleteCount);
        }
        this.forceUpdate();
    }

    private onSubmitClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        let session = this.getSessionFromState();
        const invitedUsers = this.state.invitedUsers;
        
        if(!session.bookings.length) { session.bookings = []; }

        if (!this.props.session.basketId) {
            this.props.showSpinner();
            const dropOutP: Promise<any> = session.removedUsers.length 
                ? this.props.dropOutUsers(session, session.removedUsers)
                : Promise.resolve();

            Promise.resolve(dropOutP)
                .then((updatedSession: StM.ISessionStoreState) => {
                    this.mergeUpdatedSession(session, updatedSession);
                    return this.props.saveSession(session);
                }).then(() => {
                    if(session.addedUsers.length) return Promise.resolve(this.props.checkout(session, this.props.match.params, false));
                }).then(() => {
                    const isInvitedUsersChanged = !_.isEqual(invitedUsers, this.props.session.invitedUsers);
                    if(isInvitedUsersChanged) return Promise.resolve(this.props.saveInvitedUsers(invitedUsers, session.id, ''));
                }).then(() => {
                    this.props.closeSessionInfoDialog();
                    return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
                }).then(() => {
                    this.props.hideSpinner();
                }).catch(() => {
                    this.props.hideSpinner();
                });
        } else {
            this.props.editBasketItem({ ...session });
            this.props.closeSessionInfoDialog();
        }
    }

    private joinClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club)
            );

            return;
        }

        if (!this.props.isAuthorized) {
            let hash = window.location.hash;
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } 
        else 
        {
            let session = this.getSessionFromState();

            const dropOutP: Promise<any> = session.removedUsers.length 
                ? this.props.dropOutUsers(session, session.removedUsers)
                : Promise.resolve();
            
            const isInvitedUsersChanged = !_.isEqual(this.state.invitedUsers, this.props.session.invitedUsers);
            
            if(dropOutP) this.props.showSpinner();
            
            Promise.resolve(dropOutP)
                .then((updatedSession) => {
                    if(session.removedUsers.length) {
                        _.remove(session.bookings, (b) => _.find(session.removedUsers, p => p.id === b.userId));
                        session.removedUsers = [];
                        Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params)).then(() => this.props.hideSpinner());
                    }
                    this.mergeUpdatedSession(session, updatedSession);
                    if(!this.isGroup() || session.addedUsers.length) {
                        this.props.addSessionToBasket(session);
                    }
                }).then(() => {
                    if(isInvitedUsersChanged) return Promise.resolve(this.props.saveInvitedUsers(this.state.invitedUsers, session.id, ''));
                }).then(() => {
                    this.props.closeSessionInfoDialog();
                    if (session.removedUsers.length)  return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
                }).then(() => {
                    this.props.hideSpinner();
                }).catch(() => {
                    this.props.hideSpinner();
                });
        }
    }

    private rejectInvitationClick(e: any) {
        this.props.showSpinner();
        this.props.rejectInvitation(this.props.session.id, this.props.match.params)
            .then(() => {
                this.props.hideSpinner();
                this.props.closeSessionInfoDialog();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }

    private getSkillClass(skill: string) {

        switch (skill) {
            case StM.UserSkill.Beginner: {
                return 'beginner';
            }
            case StM.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StM.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return '';
            }
        }
    }

    private getAddedUsers() {
        if(!this.save) return [];
        return this.state.players
            .filter(p => !this.save.bookings.some(b => b.userId === p.id && b.status !== StM.BookingStatus.Cancelled))
            .map(p => new StM.AddedUserStoreState({
                ...p, 
                paymentType: this.state.isPayCredits ? StM.PaymentTypes.Credits : StM.PaymentTypes.Charge
            }));
    }

    private getTimeString(date: moment.Moment) {
        return date.format('h:mm');
    }

    private getTimeZone(date: moment.Moment) {
        return date.format('a');
    }

    private getDateMonth(date: moment.Moment) {
        return date.format('MMM');
    }

    private getDateDay(date: moment.Moment) {
        return date.format('DD');
    }

    private getSessionDuration(session: StM.SessionStoreState) {
        let ms = session.endDateTime.diff(session.startDateTime);
        return moment.duration(ms).asMinutes();
    }

    private getSessionTitle(session: StM.ISessionStoreState, user: StM.IUserStoreState) {
        const notification = this.props.notification;
        let isOwner = this.sessionAvailabilityPolicy.getIsOwner();
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const isInvited = this.getIsInvited();
        const type = utils.getSessionTypeTitle(session);
        const isCourse = session.series && session.series.isCourse;
        const ownerPrefix = this.authSrv.getOwnerPrefix(user);
        let title = '';

        if (isInvited && notification) {
            return (
                <section>
                    <span className="title-invitation">{notification.message}</span>
                    {isCourse && <div className="course-title">{session.series.sessionCount} sessions</div>}
                </section>
            );
        }

        switch (session.type) {
            case StM.SessionType.Custom:
                title = `${session.title || 'Custom Session'}`;
                break;
            case StM.SessionType.Clinic:
                isOwner = false;
                title = `: ${session.title}`;
            default:
                title = ((isOwner || isGroupSession && session.type != StM.SessionType.Clinic) ? `${ownerPrefix} ` : '') + type + title;
                break;
        }

        const isVideoEnabled = utils.hasVideo(session);
        return (
            <section>
                <span className="title">{title}</span>
                {this.renderTitleBage(session)}
                {isVideoEnabled && <span className="ic_video"></span>}
                {isCourse && <div className="course-title">{session.series.sessionCount} sessions</div>}
            </section>
        );
    }

    private renderTitleBage(session: StM.ISessionStoreState) {
        const isCustom = session.type === StM.SessionType.Custom;
        const isShared = this.state.isShared;
        const title = isShared ? 'PUBLIC' : 'PRIVATE';
        const classes = ClassNames('bage', { 'open': isShared, 'close': !isShared });
        const styles: any = {};
        if (isCustom && isShared) {
            styles.backgroundColor = session.customBackgroundColor;
            styles.color = session.customTextColor;
        }

        return <span className={classes} style={styles}>{title}</span>;
    }

    private renderSlots(session: StM.ISessionStoreState) {
        let arr: Array<any> = [];
        const players = this.sessionInfoPolicy.getPlayers(true, true);
        for (let i = 0; i < session.maxUserCount; i++) {
            let classes = ClassNames("slots-item", { vacancy: i < players.length })
            arr.push(
                <span className={classes} key={i}></span>
            )
        }
        return (
            <div className="slots">
                {arr}
            </div>
        );
    }

    private renderTextSlot(session: StM.ISessionStoreState) {
        const players = this.sessionInfoPolicy.getPlayers();
        let free = session.maxUserCount - Math.min(session.maxUserCount,
            session.bookings.filter(e => e.status === 'CheckedOut').length);
        return (
            <div className="slots-text">
                {/*<span className="count-free-slots">{free}</span> of <span className="count-all-slots">{session.maxUserCount}</span> Places Free*/}
            </div>
        );
    }

    private renderSkillLvl(session: StM.ISessionStoreState) {
        if (session.playerQualification && session.playerQualification.skill) {
            let skill = session.playerQualification.skill;
            let skillClass = this.getSkillClass(skill)
            let classes = ClassNames("lvl", skillClass);
            if (!skillClass) return null;
            return (
                <div className={classes}>{skill}</div>
            )
        }
        return null;
    }

    private renderDateBlock(session: StM.SessionStoreState) {
        return (
            <div className="date-wrapper">
                <div className="date">{this.getDateDay(session.startDateTime)}</div>
                <div className="month">{this.getDateMonth(session.startDateTime)},</div>
                <div className="time-start-wrapper">
                    <div className="time-start">{this.getTimeString(session.startDateTime)}</div>
                    <div className="time-start-time-type">{this.getTimeZone(session.startDateTime)}</div>
                </div>
                <div className="time-divider">&ndash;</div>
                <div className="time-end-wrapper">
                    <div className="time-end">{this.getTimeString(session.endDateTime)}</div>
                    <div className="time-end-time-type">{this.getTimeZone(session.endDateTime)}</div>
                </div>
                <div className="session-type">
                    {(session.isDoubledSession ? "Double" : "Single") + ' Session (' + this.getSessionDuration(session) + ' min)'}
                </div>
            </div>
        );
    }


    private renderPlayerBlock(players: Array<StM.IPublicUserStoreState>, session: StM.SessionStoreState) {
        return (
            <div className="list">
                {players.filter(p => { return this.sessionAvailabilityPolicy.getIsOwner(p) }).map((player: StM.IPublicUserStoreState) => this.renderPlayerItem(player, session))}
                {players.filter(p => { return !this.sessionAvailabilityPolicy.getIsOwner(p) }).map((player: StM.IPublicUserStoreState) => this.renderPlayerItem(player, session))}
            </div>
        )
    }

    private renderPlayerItem(player: StM.IPublicUserStoreState, session: StM.SessionStoreState, markHost: boolean = true) {
        let title = utils.shortenString(player.displayName);
        const titleMarks: string[] = [];
        if (this.groupInfoPolicy.getIsMember(player.id, session)) titleMarks.push(this.groupInfoPolicy.getMemberTitleMark(player.id, true));
        if (markHost && this.sessionAvailabilityPolicy.getIsOwner(player)) titleMarks.push('host');
        if (titleMarks.length) title += ` (${titleMarks.join(', ')})`;
        return (
            <span className="players-name" key={player.id}>
                {title}
                <PlayerTooltip user={player} />
            </span>
        );
    }

    private renderAge(session: StM.SessionStoreState) {
        return (session.type == StM.SessionType.Clinic || session.type == StM.SessionType.Custom)
            ? render.renderAge(session)
            : null
            ;
    }

    private renderDescription(session: StM.SessionStoreState) {
        if (session && session.notes && (session.type == StM.SessionType.Clinic || session.type == StM.SessionType.Custom)) {
            return <div className="description" dangerouslySetInnerHTML={{ __html: session.notes }}></div>
        }
        return null;
    }

    private renderSessionPlayers(session: StM.SessionStoreState) {
        const isClinic = session.type === StM.SessionType.Clinic;
        const isCustom = session.type === StM.SessionType.Custom;
        const hasPlayers = !!this.state.players.length || !!this.state.invitedUsers.length || !!this.state.declinedUsers.length;
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const isGroup = this.isGroup();
        const groupMemberPlayers = this.state.players.filter(p => this.groupInfoPolicy.getIsMember(p.id) || (session.isPaidByOwner && this.sessionAvailabilityPolicy.getIsOwner(p)) || (!isClinic && !isCustom));

        if (!isGroupSession && (isClinic || !hasPlayers)) return null;

        return isGroup && isClinic || isCustom ? (
            (session.isPaidByOwner || (isGroup && !!groupMemberPlayers.length)) && (
                <div className="session-players">
                    {!!this.state.players.length && (
                        <div className="players">
                            <div className="title">{isGroup ? 'Players:' : 'Host:'}</div>
                            {!isGroup && (
                                <div className="list">
                                    {this.state.players.filter(p => this.sessionAvailabilityPolicy.getIsOwner(p)).map((player: StM.IPublicUserStoreState) => this.renderPlayerItem(player, session, false))}
                                </div>
                            )}
                            {isGroup && this.renderPlayerBlock(groupMemberPlayers, session)}
                        </div>
                    )}
                </div>
            )) : (
                <div className="session-players">
                    {!!this.state.players.length && <div className="players">
                        <div className="title">Players:</div>
                        {this.renderPlayerBlock(this.state.players, session)}
                    </div>}
                    {!!this.state.invitedUsers.length && <div className="players invited">
                        <div className="title">Invited:</div>
                        {this.renderPlayerBlock(this.state.invitedUsers, session)}
                    </div>}
                    {!!this.state.declinedUsers.length && <div className="players declined">
                        <div className="title">Declined:</div>
                        {this.renderPlayerBlock(this.state.declinedUsers, session)}
                    </div>}
                </div>
            );
    }

    private renderInvitationSection(session: StM.SessionStoreState) {
        const showInvitationSection = this.sessionAvailabilityPolicy.getIsFuture()
            && session.status !== StM.SessionStatus.Closed
            && (this.state.shareBoard || this.sessionAvailabilityPolicy.getIsGroupSession());
        if (!showInvitationSection) return null;
        const tabs = session.type === StM.SessionType.Clinic ? this.clinicTabs : this.tabs;
        const title = `Invite ${this.isGroup() ? 'Players' : 'a Friend'}`;
        const displayTabs = this.save.maxUserCount !== 1;
        return (
            <>
                <div className="divider-line-modal"></div>
                <div className="invite-wrapper">
                    <div className="title">{title}</div>
                    {this.renderPlayersCount(session)}
                    <div style={{display: displayTabs ? 'block' : 'none'}}>
                        <Tabs tabs={tabs}/>
                    </div>
                </div>
            </>
        );
    }

    private renderCoach(session: StM.SessionStoreState) {
        if (!session.trainerId) return null;
        
        let trainer = session.trainer || _.find(this.props.coaches, { id: session.trainerId });

        if(!trainer){
            trainer = new StM.CoachStoreState();
            trainer.displayName = "Suspended"
        }

        let image = trainer && trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="coach">
                <img className="coach-photo" src={imageUrl} />
                <span className="coach-wrapper">{trainer.displayName}</span>
                <div className="coach-tooltip">
                    <span className="coach-tooltip-title">{trainer.displayName}</span>
                </div>
            </div>
        )
    }

    private renderCourts(session: StM.SessionStoreState) {
        return <div className="court-number">{utils.getSessionCourtsTitle(session)}</div>;
    }

    private selectSearchOptions(item: any, field: string) {
        let q = this.save.playerQualification;
        let s = this.save.seeking;
        if (field == 'sex') q.sex = item.value;
        if (field == 'skill') q.skill = item.value;
        if (field == 'type') {
            if (item.key != 4) s.description = '';
            s.type = item.value;
        }
        this.forceUpdate();
    }

    private typePlayHandler(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.save.seeking.description = e.target.value;
        this.forceUpdate();
    }

    private filterCurrentUser(array: Array<any>) {
        if (this.props.user.id) {
            array = array.filter(item => item.id != this.props.user.id);
        }
        return array;
    }

    private disablePlayerSelection(count: number) {
        if (!this.props.session || !this.props.session.id) return false;
        return this.props.session.isPaidByOwner
            ? count > PolM.SessionInfoPolicy.getMaxPlayerCount(this.props.session.type)
            : count < this.sessionInfoPolicy.getPlayers().length;
    }

    setOpacity() {
        var blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
        var tabContent: any = document.getElementsByClassName('tab-content')[0];
        tabContent && (tabContent.style.opacity = '1');
    }

    private renderPlayersTab() {
        const user = this.props.user;
        const session = this.getSessionFromState();
        const isGroup = this.isGroup();
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(session, this.props.basketSessions, user);
        const isGroupOwnerSession = sessionAvailability.getIsOwnerGroupMember();
        const sessionInfoPolicy = new PolM.SessionInfoPolicy(session, this.props.user);
        const players = sessionInfoPolicy.getPlayers();

        let allAddedPlayers = [...this.state.players];

        if(isGroup) {
            allAddedPlayers.push(...user.group.members.map((m) => m.user)
            .filter((u) => session.bookings.find(b => b.userId === u.id) && !_.find(this.state.players, (p) => p.id === u.id)));

            allAddedPlayers = allAddedPlayers.filter((u) => 
            this.groupInfoPolicy.getIsMember(u.id) || (session.type != StM.SessionType.Clinic && session.type != StM.SessionType.Custom)
            || (session.isPaidByOwner && sessionAvailability.getIsOwner(u)) 
            );
        }

        const addedPlayers = this.getAddedPlayers(session);
                
        const isClinic = session.type === StM.SessionType.Clinic;
        const isCustom = session.type === StM.SessionType.Custom;
        
        let invitedPlayers = isGroup && !sessionAvailability.getIsGroupSession() && (isClinic || isCustom) ? [] : this.state.invitedUsers;

        const availableUsers: StM.IPublicUserStoreState[] = [];
        let maxAddedPlayers = 0;
        let maxInvitedPlayers = this.save.maxUserCount - players.length;
        let isNotEnoughCredits = false;
        if(isGroup) {
            const groupMembersCount = sessionAvailability.getSessionGroupMemberBookings(false);
            const nonGroupBookings = this.save.bookings.filter(b => utils.isActiveBooking(session, b) && !this.groupInfoPolicy.getIsMember(b.userId));
            const availableGroupMembers = user.group.members.filter(m => 
                !_.some(session.bookings, (b) => !b.groupId && b.userId == m.user.id && !_.includes([StM.BookingStatus.Cancelled, StM.BookingStatus.LateCancel], b.status)));

            availableUsers.push(...availableGroupMembers
                .filter(m => m.invitationStatus === StM.InvitationStatus.Accepted).map(m => m.user));
            maxAddedPlayers = this.save.maxUserCount;
            if(this.getIsNonGroupPublicSession() && nonGroupBookings.length) maxAddedPlayers -= nonGroupBookings.length;
            maxInvitedPlayers = this.save.maxUserCount - this.state.players.length;
            if(maxInvitedPlayers < 0) maxInvitedPlayers = 0;
            if(this.state.isPayCredits) {
                const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
                const prices = pricesPolicy.handle();
                if (!prices.availableCredits && (!isGroupOwnerSession || isCustom || isClinic) && (groupMembersCount.length > 0 || session.addedUsers.length)) {
                    maxAddedPlayers = groupMembersCount.length + session.addedUsers.length;
                    isNotEnoughCredits = true;
                }
            }
        }

        const isGroupHeadAMember = isGroup && !!_.find(players, (p) => this.groupInfoPolicy.getIsHead(p.id))

        if((!isGroup || isGroupOwnerSession || isGroupHeadAMember) && this.save.maxUserCount > 1) {
            availableUsers.push(...this.props.users.filter(u => u.isAvailableForInvitation && u.id !== user.id 
                && (!isGroup ||  !this.groupInfoPolicy.getIsMember(u.id))));
        }

        const inputSearchClasses = ClassNames('input-search-wrapper search-people', { 
            error: this.state.errors && this.state.errors.addedPlayers
        });

        const availableForSearchUsers = availableUsers as StM.IUserStoreState[];

        let unavailableUsers = new Array<StM.IPublicUserStoreState>();

        if(isGroup) {
            const currentSession = this.getSessionFromState();
            unavailableUsers = availableUsers.filter((user: StM.IPublicUserStoreState) => {
                const isUnavailable = this.props.sessions.some((session: StM.ISessionStoreState) => this.props.session.id !== session.id 
                    && session.bookings.some((b: StM.IBookingStoreState) => b.userId === user.id && utils.isActiveBooking(session, b))
                    && session.startDateTime.isBefore(currentSession.endDateTime) && session.endDateTime.isAfter(currentSession.startDateTime)
                );
                const booking = this.props.session.bookings.find(b => b.userId === user.id);
                const isRemoved = this.props.session.bookings.some(b => b.userId === user.id && this.groupInfoPolicy.getIsMember(user.id) && utils.isActiveBooking(session, b) && !this.state.players.some(p => p.id === b.userId));
                const isLateCancelled = !!booking && _.includes([StM.BookingStatus.LateCancel, StM.BookingStatus.LateCancelPayFail], booking.status);
                return isUnavailable || isRemoved || isLateCancelled;
            })
        }

        const updatedSession = this.getSessionFromState();
        const searchWarning = isNotEnoughCredits && "You don't have enough credits for any other group members to join";

        allAddedPlayers = allAddedPlayers
            .filter(p => !unavailableUsers.some((u) => u.id == p.id) 
                && (updatedSession.bookings.some((b) => b.status !== StM.BookingStatus.Cancelled && p.id === b.userId)
                    || this.state.players.some((a) => a.id === p.id)))
            .sort(a => session.ownerId === a.id ? -1 : 1);

        const setHeight = (elem: any) => {
            const blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
            const tabContent: any = document.getElementsByClassName('tab-content')[0];
            const invitedPlayersCount = invitedPlayers.length;
            const addedPlayersCount = isGroup ? allAddedPlayers.length : 0;

            let blockInviteWrapperHeight = isNotEnoughCredits ? 115 : 80;
            if(!!this.save && this.save.maxUserCount !== 1) blockInviteWrapperHeight += (invitedPlayersCount + addedPlayersCount) * 60 + 200;
            blockInviteWrapper.style.height = `${blockInviteWrapperHeight}px`;

            if (!isOpenPlayerList) {
                if (!firstOpen) {
                    tabContent.style.opacity = '1';

                } else {
                    tabContent.style.opacity = '0';
                    setTimeout(() => this.setOpacity(), 300);
                    isOpenPlayerList = true;
                    isOpen = false;
                    isOpenShareList = false;
                }
                firstOpen = true;
            }
        }

        return (
            <div ref={(elem) => setHeight(elem)}>
                <UserSearch placeholder='Search'
                    classes={inputSearchClasses}
                    users={availableForSearchUsers}
                    max={maxAddedPlayers}
                    maxInvited={maxInvitedPlayers}
                    unavailable={unavailableUsers}
                    multiple
                    added={addedPlayers}
                    minAdded={isGroupOwnerSession ? this.minPlayersCount : 0}
                    invited={this.state.invitedUsers as StM.IUserStoreState[]}
                    notFoundText='no players found'
                    onAddChange={(list) => this.selectPlayersToAdd(list)}
                    onInviteChange={(list) => this.selectPlayersToInvite(list as StM.IPublicUserStoreState[])}
                    addBtnText={this.getIsNonGroupPublicSession() ? 'Join' : 'Add'}
                    isNeedSort={true}
                    user={user}
                    showWarningIcon={(item) => this.shouldDisplayWarning(item)}
                    onWarningIconClick={() => this.handleWarningIconClick()}
                    searchWarning={searchWarning}
                    session={this.props.session}
                >
                    <div className='players-lists-wrapper'>
                        {isGroup && (
                            <UserList caption='Added' 
                                list={allAddedPlayers} 
                                renderItem={(item, index) => this.renderAddedUserListItem(item, index)}
                            />
                        )}
                        <UserList caption='Invited' 
                            list={invitedPlayers} 
                            showRemoveButton={(item) => this.showRemoveButton(item)}
                            removeClick={(index) => this.handlerRemoveInvitedPlayer(index)}
                            renderItemTex={(item) => this.renderUserListItemText(item)}
                        />
                    </div>
                </UserSearch>
            </div>
        );
    }

    private renderSeekingPlayerTab(session: StM.ISessionStoreState) {
        let sex: any = this.save.playerQualification ? StM.GendersInputSearchItems.find(item => item.value === this.save.playerQualification.sex) : null;
        let skill: any = this.save.playerQualification ? StM.SkillInputSearchItems.find(item => item.value === this.save.playerQualification.skill) : null;
        let type: any = this.save.seeking ? StM.PlayTypesInputSearchItems.find(item => item.value === this.save.seeking.type) : null;
        if (sex) sex = sex.key;
        if (skill) skill = skill.key;
        if (type) type = type.key;
        const isOtherType = !!session && !!session.seeking 
            && session.seeking.type === StM.PlayTypesInputSearchItems[3].value;
        const setHeight = (elem: any) => {
            const blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
            const tabContent: any = document.getElementsByClassName('tab-content')[0];
            if(this.isGroup() || !!this.save && this.save.maxUserCount !== 1) {
                blockInviteWrapper.style.height = isOtherType ? '470px' : '420px';
            }
            else
            {
                blockInviteWrapper.style.height = '80px';
            }
            

            if (!isOpen) {
                if (!firstOpen) {
                    tabContent.style.opacity = '1';
                } else {
                    tabContent.style.opacity = '0';
                    setTimeout(() => this.setOpacity(), 300);
                    isOpen = true;
                    isOpenPlayerList = false;
                    isOpenShareList = false;
                }
                firstOpen = true;
            }
        }

        return (
            <div ref={(elem) => setHeight(elem)}>
                <Switcher
                    leftLabel="PRIVATE SESSION"
                    rightLabel="PUBLIC SESSION"
                    value={this.state.isShared}
                    onSwitch={(value) => this.onSwitch(value, 'isShared')}
                />
                <div className={"form-seeking-board-wrapper" + (!this.state.isShared ? ' private' : ' open')}>
                    <div className="form-seeking-board-item">
                        <div className="label-input">What kind of player would you like to play with?</div>
                        <div className="inputs-wrapper">
                            <InputSearch
                                array={StM.GendersInputSearchItems}
                                placeholder="All Gender"
                                classes="sex-input-wrapper not-default-value"
                                selected={sex}
                                readOnly
                                disabled={!this.state.isShared}
                                onSelectChange={(item) => this.selectSearchOptions(item, 'sex')} />
                            <InputSearch
                                array={StM.SkillInputSearchItems}
                                placeholder="All Skills"
                                classes="skill-input-wrapper not-default-value"
                                selected={skill}
                                readOnly
                                disabled={!this.state.isShared}
                                onSelectChange={(item) => this.selectSearchOptions(item, 'skill')} />
                        </div>
                    </div>
                    <div className="form-seeking-board-item">
                        <div className="label-input">Type of play</div>
                        <div className="inputs-wrapper">
                            <InputSearch
                                array={StM.PlayTypesInputSearchItems}
                                classes="type-of-play-input-wrapper not-default-value"
                                placeholder="Hit"
                                selected={type}
                                readOnly
                                disabled={!this.state.isShared}
                                onSelectChange={(item) => this.selectSearchOptions(item, 'type')} />
                            {isOtherType && (
                                <input type="text" value={session.seeking.description} maxLength={50} onChange={(e) => this.typePlayHandler(e)} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderPlayersCount(session: StM.SessionStoreState) {
        const prices = this.getPrices();
        let items: any = [];
        const maxPlayerCount = PolM.SessionInfoPolicy.getMaxPlayerCount(session.type);
        const players = this.state.players;
        const playerCount = players.length;
        const isLessonPaidCredits = session.type === StM.SessionType.Private && !!prices.credits;

        for (let i = 1; i <= maxPlayerCount; i++) {
            const title = `${i} player${i >= 1 ? 's' : ''}`;
            const isOwner = this.sessionAvailabilityPolicy.getIsOwner();
            const isOwnerGroupMember = this.sessionAvailabilityPolicy.getIsOwnerGroupMember();
            const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
            const isAvailable = playerCount <= i && (isOwner || (isGroupSession && isOwnerGroupMember)) && !isLessonPaidCredits;

            let classes = ClassNames('players-item', {
                choosed: this.save.maxUserCount == i
                , disabled: !isAvailable || this.disablePlayerSelection(i)
            });
            items.push(
                <div className={classes} key={i} onClick={this.disablePlayerSelection(i) ? (_) => { } : (e) => !isAvailable || this.setMaxPlayersClick(e, i)}>{title}</div>
            )
        }
        return (
            <div className="players-wrapper">{items}</div>
        )
    }

    private renderShareLinkTab() {

        let setHeight = (elem: any) => {
            var blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
            blockInviteWrapper.style.height = this.isGroup() || !!this.save && this.save.maxUserCount !== 1 ? 350 + 'px' : '80px';

            var tabContent: any = document.getElementsByClassName('tab-content')[0];

            if (!isOpenShareList) {
                if (!firstOpen) {
                    tabContent.style.opacity = '1';
                } else {
                    tabContent.style.opacity = '0';
                    setTimeout(() => this.setOpacity(), 300);
                    isOpen = false;
                    isOpenPlayerList = false;
                    isOpenShareList = true;
                }
                firstOpen = true;
            }
        }

        const inviteLink = this.props.getSessionInviteLink(this.props.inviteToken, this.save);
        const copyClass = ClassNames("share-btn", { "share-btn-touch": utils.getIsTouchDetected() });
        return (
            <div ref={(elem) => setHeight(elem)}>
                <div className="share-url">
                    <div className="title">URL</div>
                    <div className="link-wrapper">
                        <input type="text" ref={(elem) => { this.shareLinkDOM = elem; if (elem) { elem.select() } }} value={inviteLink} onChange={(e) => false} />
                        <a className={copyClass} href="#" onClick={(e) => this.shareClickHandler(e)}>Copy</a>
                    </div>
                    <div className="share-btn-wrapper">
                        <a className="share-btn icon-btn mail" href="#" onClick={(e) => this.onSendLinkByEmailClick(e)}>Send link by email</a>
                        <a className="share-btn icon-btn facebook" href="#" onClick={(e) => this.onPostOnFacebookClick(e)}>Post on Facebook</a>
                    </div>
                </div>
            </div>
        );
    }


    private renderDropOut(session: StM.SessionStoreState) {
        const isOwner = this.sessionAvailabilityPolicy.getIsOwner();
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isCanCancel = this.sessionAvailabilityPolicy.getIsCanCancelSession();
        const isCanDropOut = this.sessionAvailabilityPolicy.getIsCanDropOut();
        const isOwnerGroupMember = this.sessionAvailabilityPolicy.getIsOwnerGroupMember();
        const isBtnShow = isOwner || (!isGroupSession && isBought) || (isGroupSession && isOwnerGroupMember);
        const isLesson = session.type === StM.SessionType.Private;
        const isPlay = session.type === StM.SessionType.Play;
        const isGroupCanCancelSession = isGroupSession && (session.isPaidByOwner || isPlay
            || !session.bookings.some(b => utils.isActiveBooking(session, b) && !this.groupInfoPolicy.getIsMember(b.userId)));

        if (!isBtnShow) return null;
        return isGroupSession || (isOwner && (!isLesson || (isLesson && (session.isPaidByOwner || this.sessionInfoPolicy.getPlayers().length <= 1)))) 
            ? ((!isGroupSession || isGroupCanCancelSession) && <div><button className="btn-cancel-session" onClick={(e) => this.cancelClick(e)} disabled={!isCanCancel}>Cancel session</button></div>) 
            : (<button className="btn-drop-out" onClick={(e) => this.dropOutClick(e)} disabled={!isCanDropOut}>Drop out</button>);
    }

    private renderPlayerFooter(session: StM.SessionStoreState, user: StM.IUserStoreState) {
        const hasFreeSlots = this.sessionAvailabilityPolicy.getIsHaveFreePlaces();
        const isCanEditOwner = this.sessionAvailabilityPolicy.getIsOwner() 
            || (this.sessionAvailabilityPolicy.getIsBoughtUser() && hasFreeSlots)
            || this.sessionAvailabilityPolicy.getIsGroupSession();
        const showSaveBtn = (this.state.shareBoard && hasFreeSlots) || isCanEditOwner;
        return (
            <div className="modal-dialog-footer-content">
                <div className="btns-wrapper">
                    <div className="left-btn-wrapper">
                        {this.renderDropOut(session)}
                    </div>
                    <div className="right-btn-wrapper">
                        <div>
                            {showSaveBtn && <button className="btn-done" onClick={(e) => this.onSubmitClick(e)}>Save</button>}
                            {!showSaveBtn && <button className="btn-done" onClick={(e) => this.closeDialogClick(e)}>Close</button>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderInvitedFooter(session: StM.SessionStoreState) {
        const isInvited = this.getIsInvited();
        const players = this.sessionInfoPolicy.getPlayers();
        const isClosed = session.status === StM.SessionStatus.Closed;
        const isLateCancelled = _.some(session.bookings, (b) => b.userId == this.props.user.id && (b.status == StM.BookingStatus.LateCancel || b.status == StM.BookingStatus.LateCancelPayFail));
        const showSaveBtn = this.state.isPayCreditsSelectionUpdated || this.state.isAddedUsersUpdated;
    
        return (
            <div className="modal-dialog-footer-content">
                <SessionPrices session={this.getSessionFromState()} />
                {!isClosed && (
                    <div className="btn-book-wrapper join-dialog-block">
                        {session.basketId || players.length >= session.maxUserCount ? (
                            <section>
                                {session.basketId ? (<button className="btn-save-book" onClick={e => this.onDeleteClick(e)}>Delete</button>) : null}
                                <button className="btn-close-session" onClick={e => showSaveBtn ? this.onSubmitClick(e) : this.closeDialogClick(e)}>
                                    {showSaveBtn ? "Save" : "Close"}
                                </button>
                            </section>
                        ) : !isLateCancelled ? isInvited ? (
                            <section>
                                {this.props.isAuthorized && (<button className="btn-edit cant-invitation" onClick={e => this.rejectInvitationClick(e)}>I can't</button>)}
                                <button className="btn-book join join-invitation" onClick={e => this.joinClick(e)}>Join</button>
                            </section>
                        ) : (<button className="btn-book" onClick={(e) => this.joinClick(e)}>{this.getIsNonGroupPublicSession() ? 'Save' : 'Join'}</button>) : ''
                        }
                    </div>
                )}
            </div>
        );
    }

    private renderSessionPrice(session: StM.ISessionStoreState) {
        const prices = this.getPrices();
        const priceWrapperClasses = ClassNames("price-wrapper", { "with-credits": this.state.isPayCredits });
        const handledPrice = utils.formatStringPrice((prices.price || session.checkoutPrice).toString());
        const showPriceDescription = this.sessionInfoPolicy.getIsCanShowPriceDescription();
        const userBooking = this.sessionInfoPolicy.getUserBooking(this.props.user.id);

        if(session.splitPrice && userBooking){
            prices.price = userBooking.amount;
        }

        const isCustom = session.type === StM.SessionType.Custom;
        const creditsBgColor = isCustom ? session.customBackgroundColor : '';
        const creditsTxtColor = isCustom ? session.customTextColor : '';

        return (
            <div className={priceWrapperClasses}>
                <div className="price-header">{StM.Strings.SessionPrice}</div>
                <div className="price-description-wrapper">
                    <div className='price-count-wrapper'>
                        {!this.state.isPayCredits && (
                            <div className="price-count">${handledPrice}</div>
                        )}
                        {this.state.isPayCredits && (
                            <Credits credits={prices.credits || session.checkoutCredits} type={session.type} bgColor={creditsBgColor} txtColor={creditsTxtColor} />
                        )}
                        {!this.state.isPayCredits && !!prices.additionalPrice && (
                            <div className='additional-price'>+ ${utils.formatStringPrice(prices.additionalPrice.toString())}</div>
                        )}
                        {this.state.isPayCredits && !!prices.additionalCredits && (
                            <div className='additional-price'>
                                + <Credits credits={prices.additionalCredits} type={session.type} bgColor={creditsBgColor} txtColor={creditsTxtColor} />
                            </div>
                        )}
                    </div>
                    <div className="description-wrapper">
                        {showPriceDescription && (session.isPaidByOwner ? StM.Strings.PaidByOwnerNoteForJoining : StM.Strings.SharedPriceNoteForJoining)}
                    </div>
                </div>
            </div>
        );
    }

    private renderServicesPrice(session: StM.ISessionStoreState) {
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, this.props.basketSessions, this.props.user);
        const isGroupSession = sessionAvailabilityPolicy.getIsGroupSession();
        const isOwnerGroupMember = sessionAvailabilityPolicy.getIsOwnerGroupMember();
        const isOwner = sessionAvailabilityPolicy.getIsOwner() || (isGroupSession && isOwnerGroupMember);
        if(!isOwner || !this.state.services.length) return null;
        const price = this.state.services.reduce((sum, s) => sum += s.price, 0);
        return (
            <div className='price-wrapper'>
                <div className="price-header">{StM.Strings.ServicesPrice}</div>
                <div className="price-count-wrapper">
                    <div className="price-count">${price}</div>
                </div>
            </div>
        );
    }

    private renderAddedUserListItem(player: StM.IPublicUserStoreState, index: number) {
        const { user, basketSessions } = this.props;
        const session = this.getSessionFromState();
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(session, basketSessions, user);
        const imageUrl = player.imageId ? utils.getImageUrl(player.imageId) : Images.NoPhoto2;
        const booking = this.props.session.bookings.find(b => b.userId === player.id);
        const isGroupBooking = !!booking && user.group && (booking.groupId === user.group.id || this.isGroup() && booking.id === 0);
        const isGroupMember = this.groupInfoPolicy.getIsMember(player.id);
        const name = this.renderUserListItemText(player, isGroupBooking);
        const isOwnerGroupMember = sessionAvailability.getIsOwnerGroupMember();
        const groupBookings = sessionAvailability.getSessionGroupMemberBookings(false, true);
        const isPaidBooking = !!booking && booking.status === StM.BookingStatus.Paid;
        const isNew = !!booking && booking.status === StM.BookingStatus.New;
        const isAvailableToRemove = isGroupMember && (!booking || (isGroupBooking && !isPaidBooking))
            && (!isOwnerGroupMember || (this.state.players.length > this.minPlayersCount && groupBookings.length > 1));

        return (
            <li className="add-list-item" key={index}>
                <div className="photo">
                    <img src={imageUrl} alt={name} />
                </div>
                <div className="name">{name}</div>
                {isAvailableToRemove && <div className="btn-container" onClick={(e) => this.handlerRemoveAddedPlayer(e, player.id)}>Remove</div>}
            </li>
        );
    }

    private renderUserListItemText(item: StM.IPublicUserStoreState, isGroupBooking: boolean = false) {
        if(!this.isGroup()) return null;
        let result = utils.shortenString(item.displayName);
        const member = isGroupBooking && this.groupInfoPolicy.getGroupMember(item.id);
        if(member) result+= ` ${this.groupInfoPolicy.getMemberTitleMark(member.user.id)}`;
        return result;
    }
    private showRemoveButton(user: StM.IPublicUserStoreState) : boolean {
        return user.id === this.props.user.id || user.invitedBy === this.props.user.id || this.props.session.ownerId === this.props.user.id;
    }

    private selectPlayersToAdd(list: StM.IAddedUserStoreState[]) {
        let players: StM.IPublicUserStoreState[] = [...list]

        if (this.isGroup()) {
            const session = this.getSessionFromState();
            const addedPlayers = this.getAddedPlayers(session);
            players = this.state.players.filter((p) => !addedPlayers.some(a => a.id === p.id)).concat([...list]);
        }
        this.setState({ ...this.state, players, isAddedUsersUpdated: true });
    }

    private selectPlayersToInvite(list: StM.IPublicUserStoreState[]) {
        const invitedUsers = [...list];
        _.each(invitedUsers, (p) => {
            if(!p.invitedBy){
                p.invitedBy = this.props.user.id;
            }
        });
        this.setState({...this.state, invitedUsers})
    }

    private handlerRemoveInvitedPlayer(index: number) {
        const invitedUsers = [...this.state.invitedUsers];
        invitedUsers.splice(index, 1);
        this.setState({...this.state, invitedUsers});
    }

    private handlerRemoveAddedPlayer(e: any, userId: string) {
        if(e) e.preventDefault();
        const players = this.state.players.filter(p => p.id !== userId);
        this.setState({...this.state, players, isAddedUsersUpdated: true});
    }

    private shareClickHandler(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.shareLinkDOM) {
            this.shareLinkDOM.select();
            document.execCommand('copy');
        }
    }

    private onDeleteClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.removeBasketSession(this.props.session);
        this.props.closeSessionInfoDialog();
    }

    private onSendLinkByEmailClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        const sharedLink = this.props.getSessionInviteLink(this.props.inviteToken, this.save);
        const mailToLink = utils.getMailToLink('', '', StM.Strings.InvitationEmailSubject, sharedLink);
        window.location.href = mailToLink;
    }

    private onPostOnFacebookClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (e) { 
          e.preventDefault(); 
          e.stopPropagation(); 
        }

        const winWidth = 520;
        const winHeight = 350;
        const sharedLink = this.props.getFBSessionInviteLink(this.props.inviteToken);
        const winTop = (window.screen.height / 2) - (winHeight / 2);
        const winLeft = (window.screen.width / 2) - (winWidth / 2);

        window.open(
          utils.getPostOnFacebookLink(sharedLink),
          'fbSharer',
          `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`
        );
      }

    private payCreditsRadioHandler(e: any) {
        this.setState({ ...this.state, isPayCredits: true, isPayCreditsSelectionUpdated: true });
    }

    private payCashRadioHandler(e: any) {
        this.save.checkoutCredits = 0;
        this.setState({ ...this.state, isPayCredits: false, isPayCreditsSelectionUpdated: true });
    }

    private onSwitch(value: boolean, field: string) {
        this.setState({ ...this.state, [field]: value });
    }

    private isGroup() {
        return this.groupInfoPolicy.getIsAuthenticated();
    }

    private getIsShowSeekingPlayerTab(){
        return !this.isGroup() && (this.sessionAvailabilityPolicy.getIsOwner() 
            || (this.sessionAvailabilityPolicy.getIsGroupSession() 
                && this.groupInfoPolicy.getIsMember(this.props.session.ownerId) 
                && this.save.maxUserCount > 1));
    }

    private getIsShowShareTab(){
        return (!this.isGroup() || _.some(this.props.session.bookings, (booking) => booking.groupId === this.props.user.group.id && booking.id !== 0));
    }

    private getIsNonGroupPublicSession() {
        return !this.props.session.isHidden && this.isGroup() && !this.groupInfoPolicy.getIsMember(this.props.session.ownerId);
    }

    private getSessionFromState(): StM.ISessionStoreState {
        if(!this.props.originCopy && !this.props.basketCopy) return null;
        const sessionSource = this.props.originCopy || this.props.basketCopy;
        const session = new StM.SessionStoreState({
            ...sessionSource,
            isHidden: !this.state.isShared,
            addedUsers: this.getAddedUsers(),
            credits: this.state.isPayCredits ? sessionSource.checkoutCredits : 0,
            maxUserCount: this.save ? this.save.maxUserCount : 0,
        });
    
        if (!this.state.isShared && session.type !== StM.SessionType.Clinic) {
            session.seekingId = null;
            session.seeking = null;
            session.playerQualification = null;
        } else if(this.state.isShared && !!this.save) {
            session.seeking = this.save.seeking;
            session.playerQualification = this.save.playerQualification;
        }

        session.removedUsers = this.isGroup() 
        ? session.bookings.filter(b => this.groupInfoPolicy.getIsMember(b.userId, session) 
        && utils.isActiveBooking(session, b) && !this.state.players.some(p => p.id === b.userId)).map((b) => b.user as StM.AddedUserStoreState)
        : [];

        const isOwner = this.sessionAvailabilityPolicy.getIsOwner();
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();

        if (isOwner || isGroupSession) {
            session.recordVideo = this.state.services.some(s => s.alias === StM.ServiceAlias.Video);
            utils.updateSessionServices(session, this.state.services);
        }

        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();
            
        session.credits = this.state.isPayCredits ? prices.additionalCredits || prices.credits : 0;

        return session;
    }

    private getPrices(): StM.ISessionPrices {
        const session = this.getSessionFromState();
        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        return pricesPolicy.handle();
    }

    private shouldDisplayWarning(item: StM.IUserStoreState): boolean {
        return this.props.session.bookings.some(b => b.userId === item.id 
            && _.includes([StM.BookingStatus.LateCancel, StM.BookingStatus.LateCancelPayFail], b.status)
        );
    }

    private handleWarningIconClick() {
        this.props.showAlert(StM.MessagesKey.GeneralWarning, StM.MessageTypes.Warning, 'The player was dropped out within the late cancellation period and couldn\'t be added again.');
    }

    private handleServicesChange = (services: StM.IAddonDefinitionStoreState[]) => {
        this.setState({ ...this.state, services });
    }

    private getAddedPlayers(session: StM.ISessionStoreState) {
        const isGroup = this.isGroup();
        if (!isGroup) return [];
        return _.reduce(this.state.players, (list: Array<IAddedUserSearchItem>, player) => {
            const booking = session.bookings.find(b => b.userId === player.id);
            const isGroupBooking = !booking || !!booking && (booking.groupId === this.props.user.group.id || booking.id === 0);
            const isCurrentGroupMember = this.groupInfoPolicy.getIsMember(player.id, session)
            const isGroupMember = isGroupBooking && isCurrentGroupMember;
            
            if(isGroupBooking && (isCurrentGroupMember || (session.type != StM.SessionType.Clinic && session.type != StM.SessionType.Custom))) {
                list.push( {
                    ...player,
                    paymentType: StM.PaymentTypes.Charge,
                    disableRemove: !isGroup || !isGroupMember,
                } as IAddedUserSearchItem)
            }

            return list;
        }, [])
    }

    private mergeUpdatedSession(source: StM.ISessionStoreState, updated: StM.ISessionStoreState) {
        if (!updated) return;
        source.bookings = [...updated.bookings];
        source.checkoutPrice = updated.checkoutPrice;
        source.checkoutCredits = updated.checkoutCredits;
        source.noServiceCheckoutPrice = updated.noServiceCheckoutPrice;
        source.videoWasRecorded = updated.videoWasRecorded;
        source.recordVideo = updated.recordVideo;
        source.videoLinkId = updated.videoLinkId;
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.sessionInfo;
    let goods = state.basket.goods;
    let session: StM.ISessionStoreState = null;
    let basketCopy = null;
    let originCopy = null;
    const agreeWithCancelationFee = false;

    if (dialog.isOpened) {
        let sessions = utils.getPageSessions(state);
        originCopy = state.dialogs.sessionInfo.session;
        const checkIsCourse = (basketItem: StM.ISessionStoreState, session: StM.ISessionStoreState): boolean => {
            const isCourse = !!session && !!session.series && session.series.isCourse && !!basketItem.series && basketItem.series.isCourse;
            return isCourse && basketItem.series.id === session.series.id;
        };

        if (dialog.from == StM.SessionInfoFromTypes.basket) {
            session = _.find(sessions, { basketId: +dialog.id }) || _.find(sessions, { id: +dialog.id });
            basketCopy = originCopy = session;
        }
        else {
            originCopy = state.dialogs.sessionInfo.session;
            basketCopy = goods.find(item => checkIsCourse(item, originCopy) || item.id === +dialog.id);
            if (basketCopy) originCopy = basketCopy;
        }

        if (session && session.basketId) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.basketId === session.basketId; });
            if (!fromBasket) session.basketId = null;
        }
        if (session && session.id) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.id === session.id; });
            if (fromBasket) session.basketId = fromBasket.basketId;
        }
    }

    session = basketCopy || originCopy; 
    const sessionInfoPolicy = new PolM.SessionInfoPolicy(session, state.user);
    const notification = sessionInfoPolicy.getSessionNotification();
    return {
        match: state.app.match,
        isShown: state.dialogs.sessionInfo.isOpened,
        isAuthorized: state.app.isAuthorized,
        from: dialog.from,
        id: dialog.id,
        inviteToken: dialog.inviteToken,
        session,
        notification,
        user: state.user,
        club: state.club,
        users: state.activeUsers,
        coaches: state.coaches,
        sessions: state.pages.book.sessions,
        addons: state.addons,
        maxPlayerCount: session ? PolM.SessionInfoPolicy.getMaxPlayerCount(session.type) : 0,
        basketSessions: state.basket.goods,
        basketCopy: basketCopy,
        originCopy: originCopy,
        agreeWithCancelationFee: agreeWithCancelationFee
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        closeSessionInfoDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SessionInfo)),
        editBasketItem: (item: StM.ISessionStoreState) => dispatch(ActM.BasketActions.edit(item)),
        addSessionToBasket: (item: StM.SessionStoreState) => { dispatch(ActM.BasketActions.add(item)); dispatch(ActM.DialogActions.open(StM.DialogNames.SessionCreateSuccess, { isExist: true })) },
        addSessionToBasketSilently: (item: StM.SessionStoreState) => { dispatch(ActM.BasketActions.add(item)); },
        rejectInvitation: (sessionId: number, params: any) => dispatch(ActM.SessionInfoDialogActions.rejectInvitation(sessionId, params)),
        saveSession: (session: StM.ISessionStoreState) => dispatch(ActM.SessionActions.save(session)),
        saveInvitedUsers: (users: Array<StM.IPublicUserStoreState>, sessionId: number, token: string) => dispatch(ActM.SessionActions.saveInvitedUsers(users, sessionId, token)),
        dropOutSession: (session: StM.ISessionStoreState, user: StM.IUserStoreState, agreeWithCancelationFee: boolean) => dispatch(ActM.SessionInfoDialogActions.dropOut(session, agreeWithCancelationFee)),
        cancelSession: (session: StM.ISessionStoreState, agreeWithCancelationFee: boolean) => dispatch(ActM.SessionInfoDialogActions.cancel(session, agreeWithCancelationFee)),
        getSessionById: (id: number, skipDialogUpdating?: boolean) => dispatch(ActM.SessionInfoDialogActions.getSessionById(id, skipDialogUpdating)),
        getSessionByInviteToken: (inviteToken: string) => dispatch(ActM.SessionInfoDialogActions.getSessionByInviteToken(inviteToken)),
        acceptSessionInviteByInviteToken: (inviteToken: string) => dispatch(ActM.SessionInfoDialogActions.acceptSessionInviteByInviteToken(inviteToken)),
        rejectSessionInviteByInviteToken: (inviteToken: string) => dispatch(ActM.SessionInfoDialogActions.rejectSessionInviteByInviteToken(inviteToken)),
        getSessionInviteToken: (sessionId: number) => dispatch(ActM.SessionInfoDialogActions.getSessionInviteToken(sessionId)),
        getSessionInviteLink: (inviteToken: string, session: StM.ISessionStoreState) => ActM.SessionInfoDialogActions.getSessionInviteLink(inviteToken, session),
        getFBSessionInviteLink: (inviteToken: string) => ActM.SessionInfoDialogActions.getFBSessionInviteLink(inviteToken),
        getSessionVideoLink: (token: string) => ActM.SessionInfoDialogActions.getSessionVideoLink(token),
        updateCurrentPageSessions: (params: any) => dispatch(ActM.SessionActions.updateForCurrentPage(params)),
        sendNotification: (notification: StM.INotificationStoreState) => dispatch(ActM.NotificationActions.create(notification)),
        showAlert: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message })),
        showCancelConfirmation: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => dispatch(ActM.DialogActions.open(StM.DialogNames.CancelConfirmation, { msgKey, messageType, message, acceptButtonText, rejectButtonText, yesCallback })),
        showAlertCancelSession: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.CancelSession, messageType: StM.MessageTypes.Success })),
        showAlertDropOutSession: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.DropOutSession, messageType: StM.MessageTypes.Success })),
        showSuccessInvitationAcceptPrompt: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.DecisionConfirmation, messageType: StM.MessageTypes.Success})),
        showErrorInvitationAcceptPrompt: (message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError, messageType: StM.MessageTypes.Error, message })),
        showRejectInvitationPrompt: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.DecisionConfirmation, messageType: StM.MessageTypes.Error})),
        removeBasketSession: (item: StM.SessionStoreState) => dispatch(ActM.BasketActions.removeById(item.basketId)),
        removeNotificationsBySession: (id: number) => dispatch(ActM.NotificationActions.removeBySessionId(id)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true)),
        dropOutUsers: (session: StM.ISessionStoreState, users: StM.IPublicUserStoreState[]) => dispatch(ActM.SessionInfoDialogActions.dropOutUsers(session, users)),
        checkout: (session: StM.ISessionStoreState, params: any, updateBasket: boolean = true) => dispatch(ActM.BookDialogActions.checkout([session], params, updateBasket)),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };

}

const connectedSessionInfoDialog = connect(mapStateToProps, mapDispatchToProps)(SessionInfoDialog);
const SessionInfoDialogController = withRouter(connectedSessionInfoDialog);
export default SessionInfoDialogController;
