import '../../dialogs.scss';
import './createSessionDialog.scss';

import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import ClassNames from 'classnames';

import {StM, ActM, PolM, SrvM } from '../../../../modules';
import UserList from '../../bookingUserList';
import { Tabs, Modal, InputSearch, Switcher, UserSearch, ModalFooter, ITabsItem } from '../../../../components';
import { ISessionStoreState } from '../../../../models/store';
import { Images } from '../../../../constants';
import { IBaseCreateSessionDialogProps } from './baseCreateSessionDialog';
import { SessionAdditionalServices } from '../sessionServices';
import { SessionPrices } from '../sessionPrices';
import Strings from "../../../../models/store/strings";

let utils = new SrvM.Utils();

let isOpen: boolean = false;
let isOpenPlayerList: boolean = false;

interface ICreateSessionDialogProps extends IBaseCreateSessionDialogProps {}

interface ICreateSessionDialogState {
    isDouble?: boolean;
    isPrivate?: boolean
    isPaidByOwner?: boolean;
    payCredits?: boolean;
    isExistSession?: boolean;
    playersNumber: number;
    credits: number;
    invitedPlayers: StM.IPublicUserStoreState[],
    addedPlayers: StM.IAddedUserStoreState[],
    services: StM.IAddonDefinitionStoreState[];
    errors?: any,
}

class CreateSessionDialog extends React.Component<ICreateSessionDialogProps, ICreateSessionDialogState> {
    private utils = new SrvM.Utils();
    private playersMap: Array<string> = [];
    private isClassInviteWrapper: string;
    private readonly courtTimeService: SrvM.CourtTimeService = new SrvM.CourtTimeService();
    private readonly playersBlockHeight = 220;
    private readonly playersListCaptionHeight = 16;
    private validator = new SrvM.ValidationService();
    private groupInfo = new PolM.GroupInfoPolicy();

    private tabs: ITabsItem[] = [
        {
            name: 'players',
            render: () => this.renderPlayersTab(),
            title: 'Players',
            classes: 'players-tab',
            getIsShow: () => this.isGroup() || this.state.playersNumber > 1
        }, {
            name: 'seeking',
            title: 'Privacy',
            classes: 'seeking-board-tab',
            render: () => this.renderSeekingPlayer(),
            getIsShow: () => !this.isGroup() && this.state.playersNumber > 1
        }
    ];

    private searchOptions: any = {
        sex: StM.GendersInputSearchItems[0].key,
        skill: StM.SkillInputSearchItems[0].key,
        type: StM.PlayTypesInputSearchItems[0].key,
        typeValue: StM.PlayTypesInputSearchItems[0].value
    }

    constructor(props: ICreateSessionDialogProps) {
        super(props);
        this.initMaxPlayers(this.props.maxPlayerCount);
        this.state = {
            isDouble: false,
            isExistSession: false,
            isPaidByOwner: false,
            payCredits: false,
            playersNumber: this.props.sessionType === StM.BookPageSessionType.Play ? 2 : 1,
            credits: 0,
            invitedPlayers: [],
            addedPlayers: [],
            errors: null,
            services: [],
        };
    }

    public componentDidMount() {
        if (this.props) this.reset();
    }

    componentDidUpdate(prevProps) {
        const isShowingChanged = !prevProps.isShow && this.props.isShow;
        const isSessionTypeChanged = !_.isEqual(prevProps.sessionType, this.props.sessionType);
        const isSessionChanged = !_.isEqual(prevProps.session, this.props.session);
        const isMaxPlayersCountChanged = !_.isEqual(prevProps.maxPlayerCount, this.props.maxPlayerCount);
        const isAuthorizedChanged = this.props.isAuthorized !== prevProps.isAuthorized;
        const isTimeChanged = !_.isEqual(this.props.time, prevProps.time);
        const isSessionParamsChanged = !_.isEqual(prevProps.sessionParams, this.props.sessionParams);
    
        if (isShowingChanged || isSessionTypeChanged || isMaxPlayersCountChanged || isAuthorizedChanged 
            || (isShowingChanged && isSessionChanged) || isTimeChanged || isSessionParamsChanged) {
            this.reset(this.props, isAuthorizedChanged);
            if (this.props.session) {
                this.initParamsFromExistSession(this.props, false, isAuthorizedChanged);
            } else if (this.props.temporarySession) {
                this.initParamsFromExistSession(this.props, true, isAuthorizedChanged || isTimeChanged);
            }
        }
    
        if (isSessionTypeChanged || isMaxPlayersCountChanged || (isShowingChanged && isSessionChanged)) {
          this.initMaxPlayers(this.props.maxPlayerCount);
        }
      }

    public componentWillUnmount() {
        this.reset();
    }

    public render() {
        if (!this.props.isShow || !this.props.court || !this.props.time) return null
        return this.renderDialog();
    }

    private renderDialog() {
        const sessionTypeClassName = this.props.session && this.props.session.type
            ? new SrvM.Utils().getFilterTypeBySessionType(this.props.session.type)
            : this.props.sessionType
            ;
        const dialogClasses = ClassNames(`${sessionTypeClassName} booking player-${this.state.playersNumber}`,
            "show-coach", {
                "club-credits": this.state.payCredits,
                group: this.isGroup()
            });
            
            const isLesson = sessionTypeClassName.toLocaleLowerCase() === 'lesson';

            const renderPaymentSharingBlock = (!this.isGroup() || this.state.invitedPlayers.length !== 0) && this.state.playersNumber > 1;

            const renderPaymentMethodsBlock = (!isLesson || this.state.playersNumber < 2) && !!this.props.time.credits;
               
            const paymentWrapperClasses = ClassNames("payment-wrapper", { "no-credits": !this.props.time.credits });
            const showPaymentSection = (!!this.props.time.credits || !this.isGroup() || this.state.invitedPlayers.length !== 0) 
                        && (renderPaymentSharingBlock || renderPaymentMethodsBlock);
            return (
                <Modal classes={dialogClasses} closeHandler={(e) => this.handleClose(e)} dialogName={StM.DialogNames.NewSession}>
                    <div className="modal-dialog">
                        {this.renderDialogHeader()}
                        <div className="modal-dialog-body">
                            <div className="info-wrapper">
                                {this.renderDateBlock()}
                                {this.renderCoachBlock()}
                                {this.renderPlayersBlock()}
                            </div>
                            {this.renderInvitationBlock()}
                            {showPaymentSection && (
                                <div className={paymentWrapperClasses}>
                                    {renderPaymentMethodsBlock && this.renderPaymentMethodsBlock()}
                                    {renderPaymentSharingBlock && this.renderPaymentSharingBlock()}
                                </div>
                            )}
                        </div>
                        <SessionAdditionalServices 
                            session={this.getSessionFromState()}
                            activeServices={this.state.services} 
                            onChange={this.handleServicesChange}
                        />
                        {this.renderDialogFooter()}
                    </div>
                </Modal>
            )
    }

    private renderDateBlock() {
        const timesRange = this.utils.getCourtTimeBlockTimesRange(this.props.time, this.getSessionDuration());

        return (
            <div className="date-wrapper">
                <div className="date">{this.props.date.date()}</div>
                <div className="month">{moment.monthsShort(this.props.date.month()) + ','}</div>
                <div className="time-start-wrapper">
                    <div className="time-start">{timesRange.start.time}</div>
                    <div className="time-start-time-type">{timesRange.start.format}</div>
                </div>
                <div className="time-divider">&ndash;</div>
                <div className="time-end-wrapper">
                    <div className="time-end">{timesRange.end.time}</div>
                    <div className="time-end-time-type">{timesRange.end.format}</div>
                </div>
            </div>
        );
    }

    private renderCoachBlock() {
        return (
            <div className="court-coach-tier-wrapper">
                {this.renderCoach()}
                <div className="court-number">
                    {StM.Strings.CourtPrefix} {this.props.court.title}
                </div>
            </div>
        );
    }

    private renderPlayersBlock() {
        return (
            <div className="players-wrapper">
                {
                    this.playersMap.map((item, index) => {
                        const playerItemClasses = ClassNames('players-item', {
                            choosed: this.state.playersNumber === (index + 1)
                        });
                        return (
                            <div className={playerItemClasses}
                                key={index}
                                onClick={(e) => this.onChosePlayerNumberClick(e, index + 1)}
                                data-testid={`btn${index + 1}Player`}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    private renderInvitationBlock() {
        if (!this.isGroup() && this.state.playersNumber <= 1) return null;
        const inviteWrapperClasses = ClassNames("invite-wrapper", this.isClassInviteWrapper);
        const title = `Invite ${this.isGroup() ? 'Players' : 'a Friend, Share the Cost'}`;
        return (
            <div className={inviteWrapperClasses}>
                <div className="title">{title}</div>
                <Tabs tabs={this.tabs} />
            </div>
        );
    }

    private renderPlayersTab() {
        const user = this.props.user;
        const isGroup = this.isGroup();
        const setHeight = () => {
            const blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
            const tabContent: any = document.getElementsByClassName('tab-content')[0];
            const invitedPlayersCount = this.state.invitedPlayers.length;
            const addedPlayersCount = this.state.addedPlayers.length;
            let blockInviteWrapperHeight = (invitedPlayersCount + addedPlayersCount) * 60 + this.playersBlockHeight;
            if(isGroup) {
                blockInviteWrapperHeight+= invitedPlayersCount ? this.playersListCaptionHeight : 0;
                blockInviteWrapperHeight+= addedPlayersCount ? this.playersListCaptionHeight : 0;
            } else if(this.state.playersNumber === 1) {
                blockInviteWrapperHeight = 0;
            }
            blockInviteWrapper.style.height = `${blockInviteWrapperHeight}px`;

            if (!isOpenPlayerList) {
                tabContent.style.opacity = '0';
                setTimeout(() => this.setOpacity(), 300);
                isOpenPlayerList = true;
                isOpen = false;
            }
        }
        const availableUsers: StM.IPublicUserStoreState[] = [];
        let maxAddedPlayers = 0;
        let maxInvitedPlayers = this.state.playersNumber;
        if(isGroup) {
            availableUsers.push(..._.sortBy(user.group.members, ['user.displayName'])
                .filter(m => m.invitationStatus === StM.InvitationStatus.Accepted).map(m => m.user));
            maxAddedPlayers = this.state.playersNumber - this.state.invitedPlayers.length;
            maxInvitedPlayers = this.state.playersNumber - this.state.addedPlayers.length;
            if(maxInvitedPlayers < 0) maxInvitedPlayers = 0;
            if(this.state.payCredits) {
                const tempSession = this.getSessionFromState();
                tempSession.addedUsers.push(new StM.AddedUserStoreState({...this.props.user, paymentType: StM.PaymentTypes.Credits}));
                const pricesPolicy = new PolM.PricesPolicy(tempSession.isDoubledSession, tempSession);
                const prices = pricesPolicy.handle();
                if(!prices.credits) maxAddedPlayers = this.state.addedPlayers.length;
            }
        } 
        if(!isGroup || !this.state.addedPlayers.length) {
            maxInvitedPlayers -= 1;
        }
        if(this.state.playersNumber > 1) {
            availableUsers.push(...this.props.users.filter(u => u.isAvailableForInvitation && u.id !== user.id 
                && (!isGroup || !this.groupInfo.getIsMember(u.id))));
        }

        let unavailableUsers = new Array<StM.IPublicUserStoreState>();

        if(isGroup) {
            const currentSession = this.getSessionFromState();
            unavailableUsers = availableUsers.filter((user: StM.IPublicUserStoreState) => {
                return this.props.sessions.some((session: StM.ISessionStoreState) => 
                    session.bookings.some((b: StM.IBookingStoreState) => b.userId === user.id && utils.isActiveBooking(session, b))
                    && session.startDateTime.isBefore(currentSession.endDateTime) && session.endDateTime.isAfter(currentSession.startDateTime)
                );
            });
        }
        
        const inputSearchClasses = ClassNames('input-search-wrapper search-people', { 
            error: this.state.errors && this.state.errors.addedPlayers 
        });
        return (
            <div ref={() => setHeight()}>
                <UserSearch placeholder='Search'
                    classes={inputSearchClasses}
                    users={availableUsers as StM.IUserStoreState[]}
                    unavailable={unavailableUsers}
                    max={maxAddedPlayers}
                    maxInvited={maxInvitedPlayers}
                    multiple
                    added={this.state.addedPlayers}
                    invited={this.state.invitedPlayers as StM.IUserStoreState[]}
                    notFoundText='no players found'
                    onAddChange={(list) => this.selectPlayersToAdd(list)}
                    onInviteChange={(list) => this.selectPlayersToInvite(list as StM.IPublicUserStoreState[])}
                    addBtnText='Add'
                    isNeedSort
                    user={user}
                    dataTestId='userSearch'
                >
                    <div className='players-lists-wrapper'>
                        <UserList caption='Added' 
                            list={this.state.addedPlayers} 
                            removeClick={(index) => this.handlerRemoveAddedPlayer(index)}
                            renderItemTex={(item) => this.renderUserListItemText(item)}
                        />
                        <UserList caption='Invited' 
                            list={this.state.invitedPlayers} 
                            removeClick={(index) => this.handlerRemoveInvitedPlayer(index)}
                            renderItemTex={(item) => this.renderUserListItemText(item)}
                        />
                    </div>
                </UserSearch>
            </div>
        );
    }

    private renderSeekingPlayer() {
        const setHeight = () => {
            var blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
            var tabContent: any = document.getElementsByClassName('tab-content')[0];
            blockInviteWrapper.style.height = this.state.playersNumber <= 1 ? 0 : (this.searchOptions.type === 4 ? '430px' : '380px');

            if (!isOpen) {
                tabContent.style.opacity = '0';
                setTimeout(() => this.setOpacity(), 300);
                isOpen = true;
                isOpenPlayerList = false;
            }
        }
        const seekingBoardClasses = ClassNames('form-seeking-board-wrapper', {
            private: this.state.isPrivate,
            open: !this.state.isPrivate
        });
        return (
            <div ref={() => setHeight()}>
                <Switcher
                    leftLabel="PRIVATE SESSION"
                    rightLabel="PUBLIC SESSION"
                    value={!this.state.isPrivate}
                    onSwitch={(value) => this.onSwitch(!value, 'isPrivate')}
                    dataTestId='swchPrivatePublicSession'
                />
                <div className={seekingBoardClasses}>
                    <div className="form-seeking-board-item">
                        <div className="label-input">What kind of player would you like to play with?</div>
                        <div className="inputs-wrapper">
                            <InputSearch
                                array={StM.GendersInputSearchItems}
                                placeholder="All Gender"
                                classes="sex-input-wrapper not-default-value"
                                selected={this.searchOptions.sex}
                                readOnly
                                disabled={this.state.isPrivate}
                                onSelectChange={(item) => this.selectSearchOptions(item, 'sex')}
                                dataTestId='gender'
                            />
                            <InputSearch
                                array={StM.SkillInputSearchItems}
                                placeholder="All Skills"
                                classes="skill-input-wrapper not-default-value"
                                selected={this.searchOptions.skill}
                                readOnly
                                disabled={this.state.isPrivate}
                                onSelectChange={(item) => this.selectSearchOptions(item, 'skill')} 
                                dataTestId='skills'
                            />
                        </div>
                    </div>
                    <div className="form-seeking-board-item">
                        <div className="label-input">Type of play</div>
                        <div className="inputs-wrapper">
                            <InputSearch
                                array={StM.PlayTypesInputSearchItems}
                                classes="type-of-play-input-wrapper not-default-value"
                                selected={this.searchOptions.type}
                                readOnly
                                disabled={this.state.isPrivate}
                                onSelectChange={(item) => this.selectSearchOptions(item, 'type')} 
                                dataTestId='playType'
                            />
                            {this.searchOptions.type == 4 && (
                                <input 
                                    type="text" 
                                    value={this.searchOptions.typeValue} 
                                    maxLength={50} 
                                    onChange={(e) => this.typePlayHandler(e)} 
                                    data-testid='otherPlayType'
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderDoubleTypeControl() {
        let filter: any = {
            sessionType: this.props.sessionType,
            lessonSubfilterId: this.props.sessionParams.coachId
        };
        let coachAvailabilityPolicy = new PolM.CoachAvailabilityPolicy(
            this.props.sessions.concat(this.props.basketSessions),
            this.props.availableTimes,
            this.props.date,
            this.props.time,
            filter,
            true
        );
        const duration = this.getSessionDuration();
        const singleDuration = this.state.isDouble ? duration / 2 : duration;
        const doubleDuration = !this.state.isDouble ? duration * 2 : duration;
        const isAllowDoubleForCoach = coachAvailabilityPolicy.handleForDouble();
        const isAllowDouble = (this.props.session && this.props.session.isDoubledSession)
            || (this.props.time.isAvailableDoubleCreation && isAllowDoubleForCoach);
        return (
            <Switcher
                leftLabel={`SINGLE SESSION (${singleDuration} min)`}
                rightLabel={`DOUBLE SESSION (${doubleDuration} min)`}
                disabled={!isAllowDouble}
                value={this.state.isDouble}
                onSwitch={(value) => this.onSwitch(value, 'isDouble')}
                dataTestId='swchSingleDoubleSession'
            />
        );
    }

    private renderDialogHeader() {
        const sessionType = this.utils.getSessionTypeByFilterType(this.props.sessionType);
        const sessionTitle = this.utils.getSessionTypeTitleByType(sessionType);
        return (
            <div className="modal-dialog-header">
                <span className="close-dialog" onClick={(e) => this.handleClose(e)}></span>
                <div className="modal-dialog-title">Book a {sessionTitle}</div>
                {this.renderDoubleTypeControl()}
                <div className="divider-line-modal"></div>
            </div>
        );
    }

    private renderDialogFooter() {
        return (
            <ModalFooter errors={this.state.errors} errorMessage={this.state.errors && this.state.errors.addedPlayers}>
                <SessionPrices session={this.getSessionFromState()} pricingTier={this.props.time.pricingTier} />
                <div className="btn-book-wrapper">
                    {this.state.isExistSession
                        ? (
                            <section className='btns-book-wrapper'>
                                <button 
                                    data-testid='deleteButton' 
                                    className="btn-view btn-save-book" 
                                    onClick={e => this.onDeleteClick(e)}
                                >
                                    DELETE
                                </button>
                                <button 
                                    data-testid='saveButton'
                                    className="btn-book btn-delete-book" 
                                    onClick={e => this.onSaveClick(e)}
                                >
                                    SAVE
                                </button>
                            </section>
                        )
                        : (<button data-testid='addToCartButton' className="btn-book" onClick={(e) => this.bookSubmit(e)}>Add to Cart</button>)
                    }
                </div>
            </ModalFooter>
        )
    }

    private renderCoach() {
        let coachId = this.props.sessionParams.coachId;
        let coach = _.find(this.props.coaches, (i) => i.id === coachId);
        if (!coach) return null;
        let imageUrl = coach.imageId ? this.utils.getImageUrl(coach.imageId) : Images.NoPhoto2;
        return (
            <div className="coach">
                <img className="coach-photo" src={imageUrl} />
                <span className="coach-wrapper">{coach.displayName}</span>
            </div>
        );
    }

    private renderPaymentMethodsBlock() {
        return (
            <div className="payment-method-wrapper">
                <div className="title">Payment Method</div>
                <div className="radio-wrapper">
                    <div className="radio-item">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input"
                                name="payment-method" value="option1"
                                data-testid='payCashRadioButton'
                                checked={!this.state.payCredits} hidden onChange={(e) => this.payCashRadioHandler(e)} />
                            <span className="form-check-text">Charge Card</span>
                        </label>
                    </div>
                    <div className="radio-item club-credits">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input"
                                name="payment-method" value="option2" hidden
                                data-testid='payCreditsRadioButton'
                                checked={this.state.payCredits} onChange={(e) => this.payCreditsRadioHandler(e)} />
                            <span className="form-check-text">Club Credits</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    private renderPaymentSharingBlock() {
        return (
            <div className="payment-sharing-wrapper">
                <div className="title">Payment Sharing</div>
                <div className="radio-wrapper">
                    <div className="radio-item">
                        <label className="form-check-label">
                            <input 
                                type="radio" 
                                className="form-check-input" 
                                name="payment-sharing" 
                                value="false" 
                                hidden 
                                checked={!this.state.isPaidByOwner} 
                                onChange={(e) => this.togglePaidOption(e, false)}
                                data-testid='btnInvitePlayerToShareCost' 
                            />
                            <span className="form-check-text">Invite player to share cost</span>
                        </label>
                    </div>
                    <div className="radio-item">
                        <label className="form-check-label">
                            <input 
                                type="radio" 
                                className="form-check-input" 
                                name="payment-sharing" 
                                value="true" 
                                hidden 
                                checked={this.state.isPaidByOwner} 
                                onChange={(e) => this.togglePaidOption(e, true)}
                                data-testid='btnPayForEntireSession'
                            />
                            <span className="form-check-text">Pay for entire session</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    private renderUserListItemText(item: StM.IPublicUserStoreState) {
        if(!this.isGroup()) return null;
        let result = this.utils.shortenString(item.displayName);
        const member = this.props.user.group.members.find(m => m.user && m.user.id === item.id);
        if(member) result+= ` ${this.groupInfo.getMemberTitleMark(member.user.id)}`;
        return result;
    }

    private setOpacity() {
        var tabContent: any = document.getElementsByClassName('tab-content')[0];
        tabContent.style.opacity = '1';
    }

    private onChosePlayerNumberClick(e: any, count: number) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        let invitedPlayers = [...this.state.invitedPlayers];
        let addedPlayers = [...this.state.addedPlayers];
        let credits = this.state.credits;
        let payCredits = this.state.payCredits;

        if(invitedPlayers.length) invitedPlayers = invitedPlayers.slice(0, count - addedPlayers.length);
        else addedPlayers = addedPlayers.slice(0, count);

        if(this.props.sessionType === StM.BookPageSessionType.Lesson){
            credits = 0;
            payCredits = false;
        }

        this.setState({
            ...this.state,
            playersNumber: count,
            invitedPlayers,
            addedPlayers,
            isPaidByOwner: this.getIsPaidByOwner(invitedPlayers.length),
            credits,
            payCredits
        });
    }

    private getIsPaidByOwner(invitedPlayersCount: number): boolean {
        return (this.isGroup() && !invitedPlayersCount) || this.state.isPaidByOwner;
    }

    private onSwitch(value: boolean, field: string) {
        let credits = this.state.credits;
        let payCredits = this.state.payCredits;
        if(field === 'isDouble' && value) {
            credits = 0;
            payCredits = false;
        }
        this.setState({ ...this.state, [field]: value, credits, payCredits });
    }

    private getBookTime(date: moment.Moment, time: moment.Duration) {
        const clone = date.clone().startOf('day');
        const datetime = clone.set({ 'hour': time.hours(), 'minute': time.minutes() });
        return datetime;
    }

    private handlerRemoveInvitedPlayer(index: number) {
        const invitedPlayers = [...this.state.invitedPlayers];
        invitedPlayers.splice(index, 1);
        this.setState({
            ...this.state, 
            invitedPlayers,
            isPaidByOwner: this.getIsPaidByOwner(invitedPlayers.length),
        });
    }

    private handlerRemoveAddedPlayer(index: number) {
        const addedPlayers = [...this.state.addedPlayers];
        addedPlayers.splice(index, 1);
        this.setState({...this.state, addedPlayers});
    }

    private selectPlayersToInvite(list: Array<StM.IPublicUserStoreState>) {
        this.setState({
            ...this.state, 
            invitedPlayers: [...list],
            isPaidByOwner: this.getIsPaidByOwner(list.length),
        });
    }

    private selectPlayersToAdd(list: Array<StM.IAddedUserStoreState>) {
        this.setState({
            ...this.state, 
            addedPlayers: [...list], 
            errors: null, 
            isPaidByOwner: this.getIsPaidByOwner(this.state.invitedPlayers.length)
        });
    }

    private selectSearchOptions(item: any, option: string) {
        this.searchOptions[option] = item.key;
        if (option == 'type') {
            this.searchOptions['typeValue'] = item.value;
        }
        this.forceUpdate();
    }

    private typePlayHandler(e: any) {
        this.searchOptions.typeValue = e.target.value;
        this.forceUpdate();
    }

    private togglePaidOption(e: any, value: boolean) {
        let credits = this.state.credits;
        let payCredits = this.state.payCredits;
        if(value && this.props.sessionType !== StM.BookPageSessionType.Play) {
            credits = 0;
            payCredits = false;
        }
        this.setState({ ...this.state, isPaidByOwner: value, credits, payCredits });
    }

    private payCreditsRadioHandler(e: any) {
        const isDouble = false;
        const pricesPolicy = new PolM.PricesPolicy(isDouble, this.props.session);
        const prices = pricesPolicy.handle();
        const credits = prices.credits;
        this.setState({ 
            ...this.state, 
            payCredits: true, 
            isDouble,
            isPaidByOwner: this.props.sessionType === StM.BookPageSessionType.Play ? this.state.isPaidByOwner : false,
            playersNumber: this.props.sessionType === StM.BookPageSessionType.Lesson ? 1 : this.state.playersNumber,
            credits
        });
    }

    private payCashRadioHandler(e: any) {
        this.setState({ ...this.state, payCredits: false });
    }

    private validatePlayers(): boolean {
        if(this.isGroup() && !this.state.addedPlayers.length) {
            const errors = this.validator.setError('addedPlayers', 'Please add a host');
            this.setState({...this.state, errors});
            return false;
        }
        return true;
    }

    private bookSubmit(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club)
            );

            return;
        }
        
        if (!this.validatePlayers()) return false;
        if (this.props.isAuthorized) this.handleClose();

        const session = this.getSessionFromState();
        if (!this.props.isAuthorized) {
            let hash = window.location.hash;
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
            if(this.props.saveTemporarySession){
                this.props.saveTemporarySession(session);
            }
        } else {
            this.props.addSessionToBasket(session);
            this.handleClose();
        }
    }

    private onSaveClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.handleClose();

        const session = this.getSessionFromState();
        const basketSession = this.props.basketSessions.find((session) => session.basketId === this.props.sessionId);
        session.basketId = basketSession.basketId;
        this.props.editBasketSession(session);
    }

    private onDeleteClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.removeBasketSession(this.props.session);
        this.reset();
        this.handleClose();
    }


    private initParamsFromExistSession(props: ICreateSessionDialogProps, isTemporary: boolean = false, keepServices: boolean) {
        const basketSeesion = props.basketSessions.find((session) => session.basketId === this.props.sessionId);
        const session = isTemporary ? props.temporarySession : basketSeesion;
        const invitedPlayers = session && session.invitedUsers ? [...session.invitedUsers] : [];
        let addedPlayers: StM.IAddedUserStoreState[] = [];
        if(this.isGroup()) {
            addedPlayers = session && session.bookings 
            ? props.users
                .filter((user) => session.bookings.some(b => b.userId === user.id && this.utils.isActiveBooking(session, b) 
                && (!session.addedUsers.length || !_.some(session.addedUsers, (u) => u.id == b.userId))))
                .map(user => {
                    
                    const booking = session.bookings.find(b => b.userId === user.id);
                    const paymentType = !!booking 
                        ? booking.paymentType 
                        : (session.checkoutCredits ? StM.PaymentTypes.Credits : StM.PaymentTypes.Charge);
                    return new StM.AddedUserStoreState({
                        ...user,
                        paymentType
                    });
                })
            : [];
            addedPlayers.push(...session.addedUsers.filter((item) => !addedPlayers.some(p => p.id === item.id)));
        }
        const credits = session.credits || session.checkoutCredits;
        this.setState({
            ...this.state,
            isDouble: !!session.isDoubledSession,
            isExistSession: !isTemporary,
            isPaidByOwner: session.isPaidByOwner,
            playersNumber: session.maxUserCount,
            payCredits: !!credits,
            credits: credits,
            isPrivate: session.isHidden,
            services: keepServices ? this.state.services : utils.getSessionServices(session, !this.isGroup() && props.user),
            invitedPlayers,
            addedPlayers,
        }, () => {
            if (session && session.playerQualification && session.seeking) {
                let sex: any = StM.GendersInputSearchItems.find(item => item.value === session.playerQualification.sex);
                let skill: any = StM.SkillInputSearchItems.find(item => item.value === session.playerQualification.skill);
                let typeOfPlay: any = StM.PlayTypesInputSearchItems.find(item => item.value === session.seeking.type);
                let description = session.seeking.description;
                if (sex) { sex = sex.key; }
                if (skill) { skill = skill.key; }
                if (typeOfPlay) { typeOfPlay = typeOfPlay.key; }
    
                this.searchOptions = {
                    sex: sex,
                    skill: skill,
                    type: typeOfPlay,
                    typeValue: description
                };
            }
        });
    }

    private initMaxPlayers(maxPlayerCount: number) {
        const maxPlayers = maxPlayerCount;
        this.playersMap = [];
        for (let i = 1; i <= maxPlayers; i++) {
            const title = `${i} player${i === 1 ? '' : 's'}`;
            this.playersMap.push(title);
        }
    }

    private handleClose(e?: any) {
        if (e) { e.stopPropagation(); }
        if(this.props.closePlayBookingDialog) {
            this.props.closePlayBookingDialog();
        }

        if(this.props.temporarySession && this.props.clearTemporarySession){
            this.props.clearTemporarySession();
        } 
    }

    private reset(props: ICreateSessionDialogProps = this.props, keepServices: boolean = false) {
        const pricesPolicy = new PolM.PricesPolicy(!!props.session && props.session.isDoubledSession, props.session)
        const prices = pricesPolicy.handle();
        const credits = prices.credits;
        this.setState({
            ...this.state,
            isDouble: false,
            payCredits: !!credits && credits > 0,
            isPaidByOwner: false,
            isExistSession: false,
            playersNumber: props.sessionType === StM.BookPageSessionType.Play ? 2 : 1,
            credits: credits,
            isPrivate: true,
            invitedPlayers: [],
            addedPlayers: [],
            errors: null,
            services: keepServices ? this.state.services : []
        });

        this.searchOptions = {
            sex: StM.GendersInputSearchItems[0].key,
            skill: StM.SkillInputSearchItems[0].key,
            type: StM.PlayTypesInputSearchItems[0].key,
            typeValue: StM.PlayTypesInputSearchItems[0].value
        };
    }

    private isGroup() {
        return this.groupInfo.getIsAuthenticated();
    }

    private setAddedUsersPaymentType(users: StM.IAddedUserStoreState[], useCredits: boolean) {
        const paymentType = useCredits ? StM.PaymentTypes.Credits : StM.PaymentTypes.Charge;
        return users.map(user => new StM.AddedUserStoreState({ ...user, paymentType }));
    }

    private getBookingsFromAddedUsers(addedUsers: StM.IAddedUserStoreState[]) : Array<StM.IBookingStoreState>{
        return addedUsers.map(user => 
            {
                const booking = new StM.BookingStoreState();
                booking.user = new StM.UserStoreState
                booking.user.id = booking.userId = user.id;
                booking.paymentType = user.paymentType;
                booking.status = StM.BookingStatus.New;
                booking.groupId = this.isGroup() && this.props.user.group.id;

                return booking;
            });
    }

    private getSessionFromState(): StM.ISessionStoreState {
        const time = this.props.time;
        if(!time) return new StM.SessionStoreState();
        
        const duration = this.getSessionDuration();
        const addedPlayers = this.state.addedPlayers;
        const startTime = time.start;
        const endTime = moment.duration(time.start).add(duration, 'm');
        const owner = (this.isGroup() && _.first(addedPlayers)) || this.props.user;
        const session = new StM.SessionStoreState({
            type: this.utils.getSessionTypeByFilterType(this.props.sessionType),
            court: this.props.court,
            courtId: this.props.court ? this.props.court.id : null,
            club: this.props.club,
            clubId: this.props.club ? this.props.club.id : null,
            minUserCount: 1,
        });

        session.owner = owner;
        session.ownerId = owner.id;
        session.maxUserCount = this.state.playersNumber;
        session.invitedUsers = [...this.state.invitedPlayers];
        session.addedUsers = this.setAddedUsersPaymentType(addedPlayers, this.state.payCredits)
        session.startDateTime = this.getBookTime(this.props.date, startTime);
        session.endDateTime = this.getBookTime(this.props.date, endTime);
        session.isPaidByOwner = this.state.isPaidByOwner;
        session.isHidden = this.state.isPrivate;
        session.isDoubledSession = this.state.isDouble;

        if (this.props.time.pricingTier) {
            session.pricingTierId = this.props.time.pricingTier.id;
            session.pricingTierType = this.props.time.pricingTier.type;
        }

        if(!this.state.isPrivate) {
            let sex: string;
            let skill: string;
            let description: string;
            let type: string;

            if (this.searchOptions.sex) {
                sex = StM.GendersInputSearchItems.find((i) => i.key === this.searchOptions.sex).value;
            }
            if (this.searchOptions.skill) {
                skill = StM.SkillInputSearchItems.find((i) => i.key === this.searchOptions.skill).value;
            }
            if (this.searchOptions.type) {
                type = StM.PlayTypesInputSearchItems.find((i) => i.key === this.searchOptions.type).value;
                description = this.searchOptions.typeValue;
            }

            session.playerQualification = new StM.PlayerQualificationStoreState({
                sex: sex,
                skill: skill
            });

            session.seeking = new StM.SeekingStoreState({
                type: type,
                description: description,
                owner: session.owner
            })
        }

        if(this.props.sessionType === StM.BookPageSessionType.Lesson) {
            const coach = this.props.coaches.find((c) => c.id === this.props.sessionParams.coachId);
            session.trainer = coach;
            session.trainerId = coach.id;
        }

        if(this.isGroup()){
            session.bookings = this.getBookingsFromAddedUsers(session.addedUsers);
        }

        session.recordVideo = this.state.services.some(s => s.alias === StM.ServiceAlias.Video);

        this.utils.updateSessionServices(session, this.state.services);

        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();

        session.credits = this.state.payCredits ?  prices.additionalCredits || prices.credits : 0;
        
        return session;
    }

    private getSessionDuration(): number {
        if(!this.props.time) return 0;
        const existedSession = this.state.isExistSession && this.props.time.session;
        let duration = this.props.time.duration;
        if(this.state.isDouble && (!existedSession || !existedSession.isDoubledSession)) {
            duration *= 2;
        } else if(!this.state.isDouble && !!existedSession && existedSession.isDoubledSession) {
            duration /= 2;
        }
        return duration;
    }

    private handleServicesChange = (services: StM.IAddonDefinitionStoreState[]) => {
        this.setState({...this.state, services });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any): ICreateSessionDialogProps => {
    let props: ICreateSessionDialogProps = {
        isShow: state.dialogs.сreateSession.isOpened,
        sessionParams: {},
        sessionId: null,
        session: null,
        isAuthorized: state.app.isAuthorized,
        temporarySession: state.temporarySession
    };

    if (props.isShow && state.pages.book.timeSlots) {
        if (state.dialogs.сreateSession.sessionId) {
            props.sessionId = +state.dialogs.сreateSession.sessionId;
            let bSession = _.find(state.basket.goods, { basketId: props.sessionId }) as ISessionStoreState;
            props.session = bSession ? { ...bSession } : null;
        }
        let timeSlot = _.find(state.pages.book.timeSlots, s => s.court.id == state.dialogs.сreateSession.courtId);
        if (timeSlot) {
            props.court = timeSlot.court;
            props.date = timeSlot.date;
            let timeBlock: any;
            for (let i = 0; i < timeSlot.timeBlocks.length; i++) {
                let block = timeSlot.timeBlocks[i];
                if (block.key.toUpperCase() == state.dialogs.сreateSession.timeKey.toUpperCase()) {
                    timeBlock = block;
                    let next = timeSlot.timeBlocks[i + 1];
                    if (next && next.isAvailableTime && next.isFilter && !next.session) {
                        props.next = next;
                    }
                    break;
                }
            }
            if (timeBlock) props.time = { ...timeBlock };
        }
    }

    let subFilterId = state.dialogs.сreateSession.sessionSubFilterId;
    let sessionType = state.dialogs.сreateSession.sessionType;
    if (sessionType) {
        props.sessionType = sessionType === StM.BookPageSessionType.Group 
            ? StM.BookPageSessionType.Play 
            : sessionType;
        const type = !!props.session && !!props.session.type
            ? props.session.type
            : new SrvM.Utils().getSessionTypeByFilterType(sessionType);
        props.maxPlayerCount = PolM.SessionInfoPolicy.getMaxPlayerCount(type);
        if (sessionType = StM.BookPageSessionType.Lesson) {
            props.sessionParams.coachId = subFilterId;
        }
    }

    props.sessions = state.pages.book.sessions;
    props.basketSessions = state.basket.goods;
    props.availableTimes = state.pages.book.availableTimes;

    props.user = state.user;
    props.club = state.club;
    props.coaches = state.coaches;
    props.coachFeeTiers = state.coachFeeTiers;
    props.court = _.find(state.club.courts, { id: state.dialogs.сreateSession.courtId });
    props.users = state.activeUsers || [];

    return props;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closePlayBookingDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.NewSession)),
        addSessionToBasket: (item: StM.SessionStoreState) => { dispatch(ActM.BasketActions.add(item)); dispatch(ActM.DialogActions.open(StM.DialogNames.SessionCreateSuccess, { isExist: false })) },
        editBasketSession: (item: StM.SessionStoreState) => dispatch(ActM.BasketActions.edit(item)),
        removeBasketSession: (item: StM.SessionStoreState) => dispatch(ActM.BasketActions.removeById(item.basketId)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        saveTemporarySession: (session: StM.ISessionStoreState) => dispatch(ActM.SessionActions.saveTemporarySession(session)),
        clearTemporarySession: () => dispatch(ActM.SessionActions.clearTemporarySession()),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSessionDialog);
