import './notifications.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import * as _ from 'lodash';
import { withRouter} from 'react-router-dom';
import Strings from "../models/store/strings";
import * as ActM from '../actions';
import * as StM from '../models/store';
import * as SrvM   from '../services';

interface INotificationsProps {
    notifications: Array<StM.INotificationStoreState>;
    club: StM.IClubStoreState;
    user: StM.UserStoreState;

    showInfo: (id:number) => Promise<any>;
    dismiss: (notification: StM.INotificationStoreState) => void;
    acceptGroupInvitation: (groupId: number) => Promise<void>;
    rejectGroupInvitation: (groupId: number) => Promise<void>;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
}

interface INotificationState {}

class Notification extends React.Component<INotificationsProps, INotificationState> {
    private utils = new SrvM.Utils();

    constructor(props: INotificationsProps) {
        super(props);
    }

    public render() {
        const notifs = this.props.notifications;
        const admins = this.filterAdminMessage(notifs).sort((a, b) => {
            return b.dateCreated.valueOf() - a.dateCreated.valueOf();
        });
        const invitations = this.filterInvitation(notifs);

        return (
            <div className="bell-hiden-content">
                <div className="top-bord">  </div>
                <div className="bell-title">
                    My Notifications
                </div>
                <div className="admin-notif-wrapper">
                    <div className="admin-notif-item-wrapper">
                        {admins.map(item => this.renderAdmin(item))}
                    </div>
                    <div className="notif-wrapper">
                        <div className="notif-item-wrapper">
                            {invitations.map(item => this.renderInvitation(item))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderAdmin(notif: StM.INotificationStoreState) {
        return (
            <div className="admin-notif-item" key={notif.id}>
                <div className="title">
                    {notif.subject}
                </div>
                <div className="message-body">
                    <p dangerouslySetInnerHTML={{__html: notif.message}}></p>
                </div>
                <div className="signature-wrapper">
                    <div className="signature-title">{this.props.club.title}</div>
                    <div className="signature-date">{notif.dateCreated.format('MMM DD, YYYY')}</div>
                    </div>
                <div className="btn-wrapper">
                    <div className="dismiss-btn" onClick={(e) => this.dismissClick(e, notif)}>Dismiss</div>
                </div>
            </div>
        );
    }
    
    private renderInvitation(notif: StM.INotificationStoreState) {
        if(!notif.target) return null;
        if(notif.targetType == StM.NotificationTargetType.Session){
            const session: StM.ISessionStoreState = notif.target;
            const start = session.startDateTime;
            const end = session.endDateTime;
            const classes = ClassNames("notif-item", this.utils.getSessionClass(session));

            const date = start.date();
            const month = start.format('MMM');
            const timeStart = start.format('h:mm');
            const timeStartType = start.format('a');
            const timeEnd = end.format('h:mm');
            const timeEndType = end.format('a');

            const iconStyles = { backgroundColor: session.customBackgroundColor };

            return (
                <div className={classes} key={notif.id}>
                    <div className="notif-time-wrapper">
                        <div className="date">{date}</div>
                        <div className="month">{month + ","}</div>
                        <div className="time-start-wrapper">
                            <div className="time-start">{timeStart}</div>
                            <div className="time-start-time-type">{timeStartType}</div>
                        </div>
                        <div className="time-divider">&ndash;</div>
                        <div className="time-end-wrapper">
                            <div className="time-end">{timeEnd}</div>
                            <div className="time-end-time-type">{timeEndType}</div>
                        </div>
                    </div>
                    <div className="title">
                        <div className="title-icon" style={iconStyles} />
                        {notif.message}
                    </div>
                    <button className="btn btn-block btn-view-notif" onClick={(e) => this.showInfoClick(e, notif)}>View</button>
                </div>
            );
        }
        if(notif.targetType == StM.NotificationTargetType.Group){
            const classes = ClassNames("notif-item");
            return (
                <div className={classes} key={notif.id}>
                    <div className="title">
                        <div className="title-icon" />
                        <span className="title-message" dangerouslySetInnerHTML={{__html: notif.message}}></span>
                    </div>
                    <div className='btns-wrapper'>
                        <button className="btn btn-block btn-red btn-view-notif" onClick={(e) => this.onAcceptGroupInvitationClick(e, notif.targetId)}>Yes, I'll join</button>
                        <button className="btn btn-block btn-view-notif" onClick={(e) => this.onRejectGroupInvitationClick(e, notif.targetId)}>No thanks</button>
                    </div>
                </div>
            );
        }
        return null;
    }

    private showInfoClick(e:any, notif: StM.INotificationStoreState) {
        if (e) {e.stopPropagation();}
        this.props.showInfo(notif.targetId);
    }

    private dismissClick(e:any, notif: StM.INotificationStoreState) {
        if (e) {e.stopPropagation();}
        this.props.dismiss(notif);
    }

    private filterAdminMessage(arr: Array<StM.INotificationStoreState>) {
        return arr.filter(item => item.targetType == StM.NotificationTargetType.User || item.targetType == StM.NotificationTargetType.Broadcast);
    }

    private filterInvitation(arr: Array<StM.INotificationStoreState>) {
        return arr.filter((item) => { 
            return (item.targetType == StM.NotificationTargetType.Session && item.event == StM.NotificationEventType.SessionYouInvited)
            || (item.targetType == StM.NotificationTargetType.Group && item.event == StM.NotificationEventType.GroupYouInvited)
            ;
        });
    }

    private onAcceptGroupInvitationClick(e: any, groupId: number) {
        if(e) e.preventDefault();
        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && this.utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club)
            );

            return;
        }
        
        this.props.acceptGroupInvitation(groupId);
    }

    private onRejectGroupInvitationClick(e: any, groupId: number) {
        if(e) e.preventDefault();
        this.props.rejectGroupInvitation(groupId);
    }
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        notifications: state.notificationBoard.notifications,
        club: state.club,
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        showInfo: (id:number) =>  dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, {from: StM.SessionInfoFromTypes.calendar, id: id})),
        dismiss: (notification: StM.INotificationStoreState) => dispatch(ActM.NotificationActions.dismiss(notification)),
        acceptGroupInvitation: (groupId: number) => dispatch(ActM.GroupAccountActions.acceptInvitationByGroupId(groupId)),
        rejectGroupInvitation: (groupId: number) => dispatch(ActM.GroupAccountActions.rejectInvitationByGroupId(groupId)),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };
}

const connectedNotifications = connect(mapStateToProps, mapDispatchToProps)(Notification);
const NotificationsController = withRouter(connectedNotifications);
export default NotificationsController;