import '../../dialogs.mobile.scss';
import './sessionInfoDialog.mobile.scss';

import ClassNames from 'classnames';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import Strings from "../../../../models/store/strings";
import { ModalMobile } from '../../../../components';
import { ISessionStoreState } from '../../../../models/store';
import { ActM, Constants, PolM, SrvM, StM } from '../../../../modules';
import OpenSessionBoardDialogMobile from '../../openSessionBoardDialog.mobile';
import SessionInvitationDialogMobile from '../../sessionInvitationDialog.mobile';
import { SessionPrices } from '../sessionPrices';
import { SessionAdditionalServices } from '../sessionServices';

let utils = new SrvM.Utils();
let render = new SrvM.SessionInfoDialogRenderService();

let isOpen: boolean = false;
let isOpenPlayerList: boolean = false;
let isOpenShareList: boolean = false;

let firstOpen: boolean = false;

interface ISessionInfoDialogProps {
    params: any;
    match: any;
    isShown: boolean;
    isAuthorized: boolean;
    from: string;
    id: any;
    inviteToken: string;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    session: StM.SessionStoreState;
    notification: StM.INotificationStoreState;
    users: Array<StM.IUserStoreState>;
    addons: Array<StM.IAddonDefinitionStoreState>;
    maxPlayerCount: number;
    basketSessions: Array<StM.ISessionStoreState>;
    basketCopy: StM.ISessionStoreState;
    originCopy: StM.ISessionStoreState;
    agreeWithCancelationFee: boolean;

    showSpinner: () => any;
    hideSpinner: () => any;
    editBasketItem: (item: StM.ISessionStoreState) => void;
    addSessionToBasket: (item: StM.ISessionStoreState) => void;
    addSessionToBasketSilently: (item: StM.SessionStoreState) => void;
    rejectInvitation: (sessionId: number, params: any) => Promise<void>;
    saveSession: (session: StM.ISessionStoreState) => Promise<StM.ISessionStoreState>;
    saveInvitedUsers: (users: Array<StM.IPublicUserStoreState>, sessionId: number, token: string) => Promise<any>;
    dropOutSession: (session: StM.ISessionStoreState, user: StM.IUserStoreState, agreeWithCancelationFee: boolean) => Promise<StM.ISessionStoreState>;
    cancelSession: (session: StM.ISessionStoreState, agreeWithCancelationFee: boolean) => Promise<StM.ISessionStoreState>;
    getSessionById: (id: number) => Promise<StM.ISessionStoreState>;
    getSessionByInviteToken: (inviteToken: string) => Promise<StM.ISessionStoreState>;
    getSessionInviteToken: (sessionId: number) => Promise<any>;
    getSessionInviteLink: (inviteToken: string, session: StM.ISessionStoreState) => string;
    getFBSessionInviteLink: (inviteToken: string) => string;
    acceptSessionInviteByInviteToken: (inviteToken: string) => Promise<StM.ISessionStoreState>;
    rejectSessionInviteByInviteToken: (inviteToken: string) => Promise<StM.ISessionStoreState>;
    updateCurrentPageSessions: (params: any) => Promise<StM.ISessionStoreState>;
    sendNotification: (notification: StM.INotificationStoreState) => void;
    showAlert: (msgKey: string) => Promise<any>;
    showAlertCancelSession: (msgKey: string) => Promise<any>;
    showAlertDropOutSession: (msgKey: string) => Promise<any>;
    showSuccessInvitationAcceptPrompt: () => Promise<any>;
    showErrorInvitationAcceptPrompt: (message: string) => Promise<any>;
    showRejectInvitationPrompt: () => Promise<any>;
    removeBasketSession: (item: StM.SessionStoreState) => void;
    getNotification: (sessionId: number) => Promise<any>;
    closeSessionInfoDialog: () => void;
    removeNotificationsBySession: (id: number) => void;
    openAuthDialog: (url: string) => Promise<any>;
    getSessionVideoLink: (token: string) => string;
    openBillingInfoDialog: () => Promise<any>;
    closeAlert: () => void;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
    showCancelConfirmation: (msgKey: string, messageType?: string, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => Promise<any>;
}

interface ISessionInfoDialogState {
    shareBoard?: boolean;
    players?: Array<StM.IPublicUserStoreState>;
    invitedUsers?: Array<StM.IPublicUserStoreState>;
    declinedUsers?: Array<StM.IPublicUserStoreState>;
    isShared?: boolean;
    playersNumber?: number;
    isInvitationDialogOpened: boolean;
    isOpenSessionBoardDialogOpened: boolean;
    payCredits?: boolean;
    payCreditsSelectionUpdated?: boolean;
    services?: StM.IAddonDefinitionStoreState[];
}

class SessionInfoDialogMobile extends React.Component<ISessionInfoDialogProps, ISessionInfoDialogState> {
    private shareVideoLinkDOM: any = null;
    private shareLinkDOM: any = null;
    private save: StM.ISessionStoreState;
    private sessionAvailabilityPolicy: PolM.SessionAvailabilityPolicy;
    private sessionInfoPolicy: PolM.SessionInfoPolicy;
    private groupInfoPolicy: PolM.GroupInfoPolicy;

    private searchOptions: any = {
        sex: StM.GendersInputSearchItems[0].key,
        skill: StM.SkillInputSearchItems[0].key,
        type: StM.PlayTypesInputSearchItems[0].key,
        typeValue: StM.PlayTypesInputSearchItems[0].value
    }

    constructor(props: ISessionInfoDialogProps) {
        super(props);
        this.save = null;
        this.sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(null, []);
        this.sessionInfoPolicy = new PolM.SessionInfoPolicy(null);
        this.state = this.getStateFromProp(props, {
            isInvitationDialogOpened: false,
            isOpenSessionBoardDialogOpened: false
        });
        this.groupInfoPolicy = new PolM.GroupInfoPolicy(null);
    }
    UNSAFE_componentWillReceiveProps(newProps: ISessionInfoDialogProps) {
        const isShowingChanged = !this.props.isShown && newProps.isShown;
        if (isShowingChanged) {
            this.reset();
        } else {
            const newState = this.getStateFromProp(newProps, this.state);
            this.setState(newState);
        }
    }

    shouldComponentUpdate(nextProps: ISessionInfoDialogProps, nextState: ISessionInfoDialogState): boolean {
        const isAuthorizedChanged = nextProps.isAuthorized && !this.props.isAuthorized;
        const isShowChanged = nextProps.isShown && !this.props.isShown;
        const isIdChanged = nextProps.id != this.props.id;
        const isFromChanged = nextProps.from !== this.props.from;
        const isSessionChanged = !_.isEqual(nextProps.session, this.props.session) || this.save == null;
        const isFromBasket = nextProps.from == StM.SessionInfoFromTypes.basket;
        if (isShowChanged || (isIdChanged && (nextProps.from != StM.SessionInfoFromTypes.inviteLink && nextProps.from != StM.SessionInfoFromTypes.inviteAcceptLink && nextProps.from != StM.SessionInfoFromTypes.inviteRejectLink))) {
            this.initSession(nextProps.id, nextProps.from);
            if (!isFromBasket) return false;
        }
        if (nextProps.isShown && (isSessionChanged || isAuthorizedChanged)) {
            this.sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(nextProps.session, nextProps.basketSessions, nextProps.user);
            this.sessionInfoPolicy = new PolM.SessionInfoPolicy(nextProps.originCopy, nextProps.user);
            this.save = nextProps.session;
        }
        return true;
    }

    render() {
        const session = this.getSessionFromState();
        if (this.props.isShown && session && this.save) {
            let user = this.props.user;
            let users = this.props.users;
            firstOpen = false;

            const classes = ClassNames("modal-session-info-mobile",
                utils.getSessionClass(session),
                { "modal-hidden": this.state.isInvitationDialogOpened || this.state.isOpenSessionBoardDialogOpened });

            if (session.playerQualification && session.seeking) {
                let sex: any = StM.GendersInputSearchItems.find(i => i.value === session.playerQualification.sex);
                let skill: any = StM.SkillInputSearchItems.find(i => i.value === session.playerQualification.skill);
                let typeOfPlay: any = StM.PlayTypesInputSearchItems.find(i => i.value === session.seeking.type);
                let description = session.seeking.description;
                if (sex) { sex = sex.key; }
                if (skill) { skill = skill.key; }
                if (typeOfPlay) { typeOfPlay = typeOfPlay.key; }
                this.searchOptions = {
                    sex: sex,
                    skill: skill,
                    type: typeOfPlay,
                    typeValue: description
                };
            }
            let maxPlayersCount = this.state.playersNumber - this.state.players.length;
            return (
                <div>
                    <ModalMobile classes={classes} dialogName={StM.DialogNames.SessionInfo}>
                        {this.renderHeader(session, user)}
                        {this.renderBody(session)}
                        <SessionAdditionalServices session={session} onChange={this.handleServicesChange} activeServices={this.state.services} />
                        {this.renderFooter(session, user)}
                    </ModalMobile>
                    <SessionInvitationDialogMobile
                        isShow={this.state.isInvitationDialogOpened}
                        maxPlayerCount={maxPlayersCount}
                        sessionType={this.props.session.type}
                        user={this.props.user}
                        users={this.props.users}
                        session={this.props.session}
                        playersList={this.state.invitedUsers}
                        selectPlayer={(list) => this.selectPlayers(list)}
                        handlePlayerRemove={(id) => this.handlerRemovePlayer(id)}
                        closeInvitationDialog={() => this.closeInvitationDialog()} />
                    {this.props.session.type !== StM.SessionType.Clinic &&
                        (<OpenSessionBoardDialogMobile
                            isShow={this.state.isOpenSessionBoardDialogOpened}
                            isPrivate={!this.state.isShared}
                            sessionType={this.props.session.type}
                            sex={StM.GendersInputSearchItems}
                            skills={StM.SkillInputSearchItems}
                            searchOptions={this.searchOptions}
                            typesOfPlay={StM.PlayTypesInputSearchItems}
                            onPrivateClick={(e) => this.onPrivate(e)}
                            offPrivateClick={(e) => this.offPrivate(e)}
                            handlePrivateClick={() => this.handlePrivate()}
                            closeOpenSessionBoardDialog={() => this.closeOpenSessionBoardDialog()}
                            selectSearchOptions={(item, option) => this.selectSearchOptions(item, option)}
                            typePlayHandler={(e) => this.typePlayHandler(e)} />)}
                </div>
            );
        } else {
            return null;
        }
    }

    renderHeader(session: StM.SessionStoreState, user: StM.IUserStoreState) {
        return (
            <div className="modal-dialog-header">
                <div className="title" style={{ borderTopColor: session.customBackgroundColor }}>
                    {this.getSessionTitle(session, user)}
                </div>
            </div>
        );
    }

    private renderBody(session: StM.SessionStoreState) {
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();
        const hasCredits = !!prices.credits || !!prices.additionalCredits;
        const isAvailable = this.sessionAvailabilityPolicy.handle();
        const isClosed = session.status === StM.SessionStatus.Closed;
        const isMaxPlayers = this.sessionAvailabilityPolicy.getIsMaxPlayers();
        const isInviteLink = this.props.from == StM.SessionInfoFromTypes.inviteLink
            && !isMaxPlayers
            && this.getIsNoBasketSessionsInTimeCurrentTime();
        const isFuture = this.sessionAvailabilityPolicy.getIsFuture();
        const isCanInvite = isFuture && !isClosed && this.sessionAvailabilityPolicy.getIsCanInvitePlayers();
      
        let blockClasses = ClassNames({ "modal-dialog-body-block": isCanInvite, "modal-dialog-body": !isCanInvite });
        let showPaymentMethodSelection = !session.splitPrice && (isAvailable || isInviteLink) && !isBought
            && !_.some(session.bookings, (b) => b.userId == this.props.user.id && (b.status == StM.BookingStatus.LateCancel || b.status == StM.BookingStatus.LateCancelPayFail))
            && hasCredits
            && this.sessionInfoPolicy.getPlayers().length < session.maxUserCount
            && utils.getIsFuture(this.props.club, session.endDateTime.clone(), moment.duration(session.endDateTime.format('H:mm')));
      
        const showInvitationSection = (isFuture && !isClosed && this.state.shareBoard);
        const isShowInviteFriends = !isClosed && (isAvailable || isInviteLink) && !this.state.shareBoard 
            && !this.state.payCreditsSelectionUpdated && isCanInvite && this.state.playersNumber > 1;

        return (
            <div className={blockClasses}>
                <div className="info-wrapper-mobile">
                    {this.renderSlotSkillBlock(session)}
                    {this.renderDateBlock(session)}
                    {this.renderCoach(session)}
                    {!this.state.shareBoard &&
                        (<div className="gender-age-wrapper-mobile">
                            {this.renderGender(session)}
                            {this.renderAge(session)}
                        </div>)}
                    {this.renderDescription(session)}
                    {this.renderSessionPlayers(session)}
                    {showInvitationSection && (
                        <div className="share-board-wrapper-mobile">
                            {this.renderInvitationBlock()}
                            {this.renderOpenBoardBlock()}
                        </div>
                    )}
                    {showPaymentMethodSelection && this.renderPaymentSelection(session)}
                    {isShowInviteFriends && <button className="btn-invite btn-mobile btn-black" onClick={(e) => this.inviteClick(e)}>Invite friends</button>}
                </div>
            </div>
        );
    }

    private payCreditsRadioHandler(e: any) {
        this.setState({ ...this.state, payCredits: true, payCreditsSelectionUpdated: true });
    }

    private payCashRadioHandler(e: any) {
        this.setState({ ...this.state, payCredits: false, payCreditsSelectionUpdated: true });
    }

    renderPaymentSelection(session: StM.SessionStoreState) {
        return (<div className="payment-wrapper-mobile">
            <div className="payment-method-wrapper-mobile">
                <div className="title">Payment Method</div>
                <div className="form-wrapper">
                    <div className="radio-wrapper">
                        <div className="radio-item">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input"
                                    name="payment-method" value="option1"
                                    checked={!this.state.payCredits} hidden onChange={(e) => this.payCashRadioHandler(e)} />
                                <span className="form-check-text">Charge Card</span>
                            </label>
                        </div>
                        <div className="radio-item club-credits-mobile">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input"
                                    name="payment-method" value="option2" hidden
                                    checked={this.state.payCredits} onChange={(e) => this.payCreditsRadioHandler(e)} />
                                <span className="form-check-text">Club Credits</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    private renderFooter(session: StM.SessionStoreState, user: StM.IUserStoreState) {
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isInviteLink = this.props.from == StM.SessionInfoFromTypes.inviteLink
            && !this.sessionAvailabilityPolicy.getIsMaxPlayers()
            && this.getIsNoBasketSessionsInTimeCurrentTime()
            ;
        const isShowInviteFooter = !isBought && (this.sessionAvailabilityPolicy.handle() || isInviteLink);
        const isClosed = session.status === StM.SessionStatus.Closed;
        
        if (isClosed && !isShowInviteFooter) return null;
        return (
            <div>
                <div className="modal-dialog-body">
                    {isShowInviteFooter ? this.renderInvitedFooter(session) : this.renderPlayerFooter(session, user)}
                </div>
            </div>
        );
    }

    private initSession(id: any, from: string): Promise<any> {
        if (!id) return Promise.resolve();
        let prom: Promise<any> = null;
        const sessionProcess = (session: StM.ISessionStoreState) => {
            if (!session) {
                this.props.closeSessionInfoDialog();
                this.props.removeNotificationsBySession(+id);
                this.props.showAlert(StM.MessagesKey.SessionNotFound);
                this.props.updateCurrentPageSessions(this.props.match.params);
            }
            else if(from == StM.SessionInfoFromTypes.inviteAcceptLink) {
                if(session.validationResult && session.validationResult.errors && session.validationResult.errors.length) {
                    const error = session.validationResult.errors[0];
                    if(error.type == 'billing'){
                        this.props.closeSessionInfoDialog();
                        this.props.addSessionToBasketSilently(session);
                        if(this.props.isAuthorized) this.props.openBillingInfoDialog();
                        else {
                            let hash = new SrvM.RouteDialogService().getDialogHash(StM.DialogNames.BillingInfo, {});
                            this.props.openAuthDialog(encodeURIComponent(hash));
                        }
                    }
                    this.props.showErrorInvitationAcceptPrompt(error.message);  
                }
                else {
                    this.props.showSuccessInvitationAcceptPrompt();
                    this.props.updateCurrentPageSessions(this.props.match.params);
                }
            }
            else if(from == StM.SessionInfoFromTypes.inviteRejectLink) {
                this.props.closeSessionInfoDialog();
                this.props.showRejectInvitationPrompt();
                this.props.updateCurrentPageSessions(this.props.match.params);
            }
            return session;
        };

        if (from == StM.SessionInfoFromTypes.inviteLink) {
            prom = this.props.getSessionByInviteToken(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                });
        }
        else if (from == StM.SessionInfoFromTypes.inviteAcceptLink) {
            prom = this.props.acceptSessionInviteByInviteToken(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                }).catch(() => {
                    this.props.closeSessionInfoDialog();
                    this.props.showAlert(StM.MessagesKey.SessionNotFound);
                    if(this.props.isAuthorized) this.props.updateCurrentPageSessions(this.props.match.params); 
                });
        }
        else if (from == StM.SessionInfoFromTypes.inviteRejectLink) {
            prom = this.props.rejectSessionInviteByInviteToken(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                });
        }
        else if (from !== StM.SessionInfoFromTypes.basket) {
            prom = this.props.getSessionById(id)
                .then((session: StM.ISessionStoreState) => {
                    return sessionProcess(session);
                });
        }

        if (prom) {
            this.props.showSpinner();
            return prom
                .then((session: StM.ISessionStoreState) => {

                    this.props.hideSpinner();
                }).catch(() => {
                    this.props.hideSpinner();
                });
        }
        return Promise.resolve();
    }

    private getStateFromProp(props: ISessionInfoDialogProps, state: ISessionInfoDialogState, updatePayCredits: boolean = true): ISessionInfoDialogState {
        const session = props.basketCopy || props.session;
        if(!session) return null;
        const sessionInfo = new PolM.SessionInfoPolicy(session);
        const pricesPolicy = new PolM.PricesPolicy(props.session.isDoubledSession, props.session);
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(props.session, props.basketSessions, props.user);
        const hasFreeSlots = this.sessionAvailabilityPolicy.getIsHaveFreePlaces();
        const isShared = this.getIsShared(session);
        const notActiveBooking = props.user ? session.bookings.find(b => b.userId === props.user.id && !utils.isActiveBooking(session, b)) : null;
        const isNotActiveBookingChecked = !notActiveBooking || notActiveBooking.paymentType === StM.PaymentTypes.Credits;
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isOwner = sessionAvailabilityPolicy.getIsOwner();
        const prices = pricesPolicy.handle();
        const payCredits = props.basketCopy 
            ? !!props.basketCopy.credits 
            : !!(!!prices.credits && (isOwner || isBought || hasFreeSlots) && isNotActiveBookingChecked);
        return {
            invitedUsers: sessionInfo.getInvitedUsers(),
            declinedUsers: sessionInfo.getDeclinedUsers(),
            players: sessionInfo.getPlayers(),
            shareBoard: false,
            isShared: isShared,
            playersNumber: session ? session.maxUserCount : 0,
            isInvitationDialogOpened: false,
            isOpenSessionBoardDialogOpened: false,
            payCredits,
            services: utils.getSessionServices(session, props.user),
        }
    }

    private getIsShared(session: StM.ISessionStoreState): boolean {
        return session && !session.isHidden;
    }

    private getYourBooking(session: StM.ISessionStoreState) {
        for (let i = 0; i < session.bookings.length; i++) {
            let booking = session.bookings[i];
            if (booking.userId == this.props.user.id && utils.isActiveBooking(session, booking)) {
                return booking;
            }
        }
        return null;
    }

    private getIsInvited(): boolean {
        const isInvited = this.sessionAvailabilityPolicy.getIsInvited();
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isInviteLink = this.props.from == StM.SessionInfoFromTypes.inviteLink;
        return (isInvited || isInviteLink) && !isBought;
    }

    reset() {
        this.save = null;
        this.setState({
            shareBoard: false,
            isInvitationDialogOpened: false,
            isOpenSessionBoardDialogOpened: false,
            payCreditsSelectionUpdated: false,
            services: [],
        });
        this.searchOptions = {
            sex: StM.GendersInputSearchItems[0].key,
            skill: StM.SkillInputSearchItems[0].key,
            type: StM.PlayTypesInputSearchItems[0].key,
            typeValue: StM.PlayTypesInputSearchItems[0].value
        };
    }
    // handlers
    closeClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeSessionInfoDialog();
    }

    inviteClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.enshureSharedFields();
        this.setState({ ...this.state, shareBoard: true })
    }

    private enshureSharedFields() {
        if (!this.save.playerQualification) {
            this.save.playerQualification = new StM.PlayerQualificationStoreState({});
        }
        else {
            this.save.playerQualification = { ...this.save.playerQualification };
        }

        if (!this.save.seeking) {
            this.save.seeking = new StM.SeekingStoreState({});
        } else {
            this.save.seeking = { ...this.save.seeking };
        }
    }

    backClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.save = { ... this.props.session as ISessionStoreState };
        let state = this.getStateFromProp(this.props, this.state);
        this.setState(state);
    }

    private dropOutClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.showConfirmationDialog(StM.MessagesKey.DropOutConfirmation, this.dropOutSessionAction.bind(this));
    }

    private dropOutSessionAction (agreeWithCancelationFee: boolean) {
        this.props.showSpinner();
        this.props.dropOutSession(this.props.session, this.props.user, agreeWithCancelationFee)
            .then(() => {
                return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
            }).then(() => {
                this.props.closeSessionInfoDialog();
                this.props.showAlertDropOutSession(StM.MessagesKey.DropOutSession);
                this.props.hideSpinner();
            }).catch((error: any) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                    const message: string = error.response.data.exceptionMessage;
                    if (message.indexOf('Session contains player bookings') > -1) { //'Session contains player bookings that cannot be cancelled.'
                        this.props.showAlert(StM.MessagesKey.UnableCheckedInDropOutSession);
                    }
                }
            });
    }

    private cancelClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.showConfirmationDialog(StM.MessagesKey.CancelConfirmation, this.cancelSessionAction.bind(this));
    }

    private cancelSessionAction (agreeWithCancelationFee: boolean) {
        this.props.showSpinner();
        this.props.cancelSession(this.props.session, agreeWithCancelationFee)
        .then(() => {
            return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
        }).then(() => {
            this.props.closeSessionInfoDialog();
            this.props.showAlertCancelSession(StM.MessagesKey.CancelSession);
            this.props.hideSpinner();
        }).catch((error: any) => {
            this.props.hideSpinner();
            if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                const message: string = error.response.data.exceptionMessage;
                if (message.indexOf('Unable to cancel session') > -1) { //'Unable to cancel session when at least one user is checked in'
                    this.props.showAlert(StM.MessagesKey.UnableCheckedInCancelSession);
                }
            }
        });
    }

    private showConfirmationDialog(messageKey: string, acceptAction: (agreeWithCancelationFee: boolean) => void) {
        const isWithinLateCancellationFeePeriod = utils.isWithinLateCancellationFeePeriod(this.props.session.startDateTime, this.props.club);
        const minimalCancellationPeriod = this.props.club.minimalCancellationPeriod;
        const sessionStartDateTime = this.props.session.startDateTime.toDate();
        const isFeeInPercent = this.props.club.isLateCancellationFeeInPercent;
        const lateCancellationFee =
            isFeeInPercent
            ? this.props.club.lateCancellationFee === 100
                ? "full price"
                : this.props.club.lateCancellationFee + '%25 of the price'
            : this.props.club.lateCancellationFee + '$';

        const message = `If you proceed, you'll be charged ${lateCancellationFee} as you're within ${minimalCancellationPeriod} hours of your session time.`; 
        const acceptButtonText = "Yes, I`m sure";
        const rejectButtonText = "No, I`ll keep my session";
        
        const isCanceledWithinPeriod = utils.isCanceledWithinPeriod(
            sessionStartDateTime, 
            minimalCancellationPeriod, 
            this.props.club.aliasedTimeZone.dbName
        );
        if (isCanceledWithinPeriod) {
            this.props.showCancelConfirmation(
                messageKey,
                StM.MessageTypes.Warning, 
                message,
                acceptButtonText,
                rejectButtonText,
                () => acceptAction(isCanceledWithinPeriod)
                );
        } else {
            acceptAction(isCanceledWithinPeriod);
        }
    }

    private setMaxPlayersClick(e: any, max: number) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.save.maxUserCount = max;

        if (max < this.save.invitedUsers.length + 1) {
            let deleteCount = this.save.invitedUsers.length + 1 - max;
            this.save.invitedUsers.splice(0, deleteCount);
        }
        this.forceUpdate();
    }

    private getSessionFromState(): StM.ISessionStoreState {
        if(!this.props.originCopy && !this.props.basketCopy) return null;
        const sessionSource = this.props.originCopy || this.props.basketCopy;
        const session = new StM.SessionStoreState({
            ...sessionSource,
            isHidden: !this.state.isShared,
            credits: this.state.payCredits ? sessionSource.credits : 0,
            maxUserCount: this.save ? this.save.maxUserCount : 0,
        });
    
        if (!this.state.isShared && session.type !== StM.SessionType.Clinic) {
            session.seekingId = null;
            session.seeking = null;
            session.playerQualification = null;
        } else if(this.state.isShared && !!this.save) {
            session.seeking = this.save.seeking;
            session.playerQualification = this.save.playerQualification;
        }

        const pricesPolicy = new PolM.PricesPolicy(this.props.session.isDoubledSession, session);
        const prices = pricesPolicy.handle();

        session.credits = this.state.payCredits ? prices.credits : 0;

        session.isHidden = !this.state.isShared;

        const isOwner = this.sessionAvailabilityPolicy.getIsOwner();
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();

        if (isOwner || isGroupSession) {
            session.recordVideo = this.state.services.some(s => s.alias === StM.ServiceAlias.Video);
            utils.updateSessionServices(session, this.state.services);
        }
        
        return session;
    }

    onSubmitClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        const session = this.getSessionFromState();

        if (!this.props.session.basketId) {
            this.props.showSpinner();
            this.props.saveSession(session)
                .then(() => {
                    return this.props.saveInvitedUsers(this.save.invitedUsers, this.save.id, '');
                }).then(() => {
                    return this.props.updateCurrentPageSessions(this.props.match.params);
                }).then(() => {
                    this.props.hideSpinner();
                    this.props.closeSessionInfoDialog();
                }).catch(() => {
                    this.props.hideSpinner();
                });
        } else {
            this.props.editBasketItem(session);
            this.props.closeSessionInfoDialog();
        }

    }

    private joinClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club)
            );

            return;
        }

        if (!this.props.isAuthorized) {
            let hash = window.location.hash;
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else {
            const session = this.getSessionFromState();
            if (!this.state.payCredits) {
                session.checkoutCredits = 0;
            }
            this.props.addSessionToBasket(session);
            this.props.closeSessionInfoDialog();
        }
    }

    private rejectInvitationClick(e: any) {
        this.props.showSpinner();
        this.props.rejectInvitation(this.props.session.id, this.props.match.params)
            .then(() => {
                this.props.hideSpinner();
                this.props.closeSessionInfoDialog();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }

    private getSkillClass(skill: string) {
        switch (skill) {
            case StM.UserSkill.Beginner: {
                return 'beginner';
            }
            case StM.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StM.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return '';
            }
        }
    }

    private getTimeString(date: moment.Moment) {
        return date.format('h:mm');
    }

    private getTimeZone(date: moment.Moment) {
        return date.format('a');
    }

    private getDateMonth(date: moment.Moment) {
        return date.format('MMM');
    }

    private getDateDay(date: moment.Moment) {
        return date.format('DD');
    }

    private getSessionDuration(session: StM.SessionStoreState) {
        let ms = session.endDateTime.diff(session.startDateTime);
        return moment.duration(ms).asMinutes();
    }

    private getSessionTitle(session: StM.ISessionStoreState, user: StM.IUserStoreState) {
        const notification = this.props.notification;
        let isOwner = new PolM.SessionAvailabilityPolicy(session, this.props.basketSessions, user).getIsOwner();
        const isInvited = this.getIsInvited();
        const type = utils.getSessionTypeTitle(session);
        const isCourse = session.series && session.series.isCourse;
        let title = '';

        if (isInvited && notification) {
            return (
                <section>
                    <div className="title-invitation-mobile">{notification.message}</div>
                    {isCourse && <div className="course-title">{session.series.sessionCount} sessions</div>}
                </section>
            );
        }

        switch (session.type) {
            case StM.SessionType.Custom:
                title = `${session.title || 'Custom Session'}`;
                break;
            case StM.SessionType.Clinic:
                isOwner = false;
                title = `: ${session.title}`;
            default:
                title = (isOwner ? 'My ' : '') + type + title;
                break;
        }

        return (<section>
            <span>{title}</span>
            {isCourse && <div className="course-title">{session.series.sessionCount} sessions</div>}
            {this.renderTitleBage(session)}
        </section>);
    }

    private getInputSearchUsers(array: Array<StM.PublicUserStoreState>) {
        array = this.filterCurrentUser(array);
        return array.map(item => {
            return {
                key: item.id,
                value: item.displayName
            }
        })
    }

    private renderTitleBage(session: StM.ISessionStoreState) {
        let bageClasses = ClassNames("bage", { "open": this.state.isShared, "close": this.state.isShared });
        let title = this.state.isShared ? "PUBLIC" : "PRIVATE";
        const isCustom = session.type === StM.SessionType.Custom;
        const styles: any = {};
        if (isCustom && this.state.isShared) {
            styles.backgroundColor = session.customBackgroundColor;
            styles.color = session.customTextColor;
        }
        const isVideoEnabled = utils.hasVideo(session);

        return this.props.session.type != StM.SessionType.Clinic ? (
            <div className="badge-mobile">
                <span className={bageClasses} style={styles}>{title}</span>
                {isVideoEnabled && <span className="ic_video"></span>}
            </div>
        ) : null;
    }

    private renderSlots(session: StM.ISessionStoreState) {
        const playersCount = this.sessionInfoPolicy.getPlayers(true, true).length;
        let arr: Array<any> = [];

        for (let i = 0; i < session.maxUserCount; i++) {
            let classes = ClassNames("slots-item",
                { "one-slot": i == 0 },
                { "two-slot": i == 1 },
                { "three-slot": i == 2 },
                { "four-slot": i == 3 },
                { "five-slot": i == 4 },
                { "much-slot": i > 4 },
                { vacancy: i < playersCount })
            arr.push(<span className={classes} key={i}></span>)
        }

        let classesSlots = ClassNames("slots-mobile",
            { "one-player": session.maxUserCount == 1 },
            { "two-players": session.maxUserCount == 2 },
            { "three-players": session.maxUserCount == 3 },
            { "four-players": session.maxUserCount == 4 },
            { "more-than-5": session.maxUserCount > 4 },
        );
        return (
            <div className={classesSlots}>
                {arr}
            </div>
        );
    }

    private renderSkillLvl(session: StM.ISessionStoreState) {
        if (session.playerQualification && session.playerQualification.skill) {
            let skill = session.playerQualification.skill;
            let skillClass = this.getSkillClass(skill)
            let classes = ClassNames("lvl", skillClass);
            if (!skillClass) return (
                <div className="skill">
                    {session.playerQualification.skill}
                </div>
            );
            return (
                <div className="skill">
                    <div className={classes}></div>
                    <span>{skill}</span>
                </div>
            )
        }
        return null;
    }

    private renderSlotSkillBlock(session: StM.SessionStoreState) {
        return (
            <div className="session-info-wrapper-mobile">
                <div className="mobile-row slot-skill-table-mobile flex-center-mobile">
                    <div className="mobile-col-7 slots-wrapper-mobile">
                        {this.renderSlots(session)}
                    </div>
                    {(session.playerQualification && session.playerQualification.skill) &&
                        (<div className="mobile-col-5 skill-wrapper-mobile">
                            {this.renderSkillLvl(session)}
                        </div>)
                    }
                </div>
            </div>
        );
    }

    private renderDateBlock(session: StM.SessionStoreState) {
        return (
            <div className="date-time-wrapper-mobile">
                <div className="date-wrapper-mobile mobile-row">
                    <div className="mobile-col-12">
                        <span className="date-mobile">{this.getDateDay(session.startDateTime)} </span>
                        <span className="month-mobile">{this.getDateMonth(session.startDateTime)},</span>
                    </div>
                </div>
                <div className="mobile-row flex-baseline-mobile">
                    <div className="time-range-wrapper-mobile mobile-col-6 flex-baseline-mobile">
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{this.getTimeString(session.startDateTime)}</div>
                            <div className="time-type-mobile">{this.getTimeZone(session.startDateTime)}</div>
                        </div>
                        <div className="time-divider-mobile">&ndash;</div>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{this.getTimeString(session.endDateTime)}</div>
                            <div className="time-type-mobile">{this.getTimeZone(session.endDateTime)}</div>
                        </div>
                    </div>
                    <div className="mobile-col-6 court-wrapper-mobile">
                        {this.renderCourts(session)}
                    </div>
                </div>
            </div>
        );
    }

    private renderPlayerBlock(players: Array<StM.IPublicUserStoreState>) {
        return (
            <div className="list-mobile">
                {players.filter(p => { return this.sessionAvailabilityPolicy.getIsOwner(p) }).map((player: StM.IPublicUserStoreState) => this.renderPlayerItem(player))}
                {players.filter(p => { return !this.sessionAvailabilityPolicy.getIsOwner(p) }).map((player: StM.IPublicUserStoreState) => this.renderPlayerItem(player))}
            </div>
        )
    }

    private renderPlayerItem(player: StM.IPublicUserStoreState, markHost: boolean = true) {
        let title = player.displayName;
        if (title.length > Constants.NameLength) {
            title = title.substring(0, Constants.NameLength - 3) + '...';
        }
        if (markHost) {
            const isOwner = this.sessionAvailabilityPolicy.getIsOwner(player);
            title = (isOwner ? title + ' (host)' : title);
        }
        return (
            <span className="players-name-mobile" key={player.id}>{title}</span>
        );
    }

    private renderGender(session: StM.SessionStoreState) {
        if (session.playerQualification && session.playerQualification.sex) {
            if (session.type == StM.SessionType.Clinic || session.type == StM.SessionType.Custom) {
                return <span className="gender-mobile">{session.playerQualification.sex}</span>
            }
        }
        return null;
    }

    private renderAge(session: StM.SessionStoreState) {
        return (session.type == StM.SessionType.Clinic || session.type == StM.SessionType.Custom)
            ? render.renderAge(session)
            : null;
    }

    private renderDescription(session: StM.SessionStoreState) {
        if (!this.state.shareBoard && session && session.notes && (session.type == StM.SessionType.Clinic || session.type == StM.SessionType.Custom)) {
            return (
                <div className="description-wrapper-mobile">
                    <div className="divider-line"></div>
                    <div className="description-mobile" dangerouslySetInnerHTML={{ __html: session.notes }}></div>
                </div>)
        }
        return null;
    }

    renderInvitationBlock() {
        let invitedPlayers = this.state.invitedUsers ? this.state.invitedUsers.length : 0;
        let statusClasses = ClassNames("status-mobile", { "active": invitedPlayers > 0 });
        return (
            this.state.playersNumber > 1 ?
                (<div className="interactive-block-wrapper-mobile mobile-row" onClick={(e) => this.openInvitationDialog(e)}>
                    <div className="caption-mobile mobile-col-6">INVITE PLAYERS</div>
                    <div className="status-wrapper-mobile mobile-col-6">
                        <span className={statusClasses}>{invitedPlayers} Invited</span>
                        <img src="/content/img/red_arrow_mobile.svg" />
                    </div>
                </div>) :
                (null)
        );
    }

    renderOpenBoardBlock() {
        let statusClasses = ClassNames("status-mobile", { "active": this.state.isShared });
        let isOwner = this.sessionAvailabilityPolicy.getIsOwner();
        return (
            (isOwner && this.props.session.type !== StM.SessionType.Clinic && this.state.playersNumber > 1) ?
                (<div className="interactive-block-wrapper-mobile mobile-row" onClick={(e) => this.openSessionBoardDialog(e)}>
                    <div className="caption-mobile mobile-col-6">PRIVACY</div>
                    <div className="status-wrapper-mobile mobile-col-6">
                        <span className={statusClasses}>{!this.state.isShared ? "Private" : "Public"}</span>
                        <img src="/content/img/red_arrow_mobile.svg" />
                    </div>
                </div>) :
                (null)
        );
    }

    private renderSessionPlayers(session: StM.SessionStoreState) {
        const isClinic = session.type === StM.SessionType.Clinic;
        const hasPlayers = !!this.state.players.length || !!this.state.invitedUsers.length || !!this.state.declinedUsers.length;

        if (isClinic || !hasPlayers || this.state.shareBoard) return null;

        return session.type === StM.SessionType.Custom ? (
            session.isPaidByOwner && (
                <div className="session-players-mobile">
                    {!!this.state.players.length && (
                        <div className="players-mobile">
                            <div className="title-mobile">Host:</div>
                            <div className="list-mobile">
                                {this.state.players.filter(p => { return this.sessionAvailabilityPolicy.getIsOwner(p) }).map((player: StM.IPublicUserStoreState) => this.renderPlayerItem(player, false))}
                            </div>
                        </div>
                    )}
                </div>
            )) : (
                <div className="session-players-mobile">
                    {!!this.state.players.length && <div className="players-mobile">
                        <div className="title-mobile">Players:</div>
                        {this.renderPlayerBlock(this.state.players)}
                    </div>}
                    {!!this.state.invitedUsers.length && <div className="players-mobile invited">
                        <div className="title-mobile">Invited:</div>
                        {this.renderPlayerBlock(this.state.invitedUsers)}
                    </div>}
                    {!!this.state.declinedUsers.length && <div className="players-mobile declined">
                        <div className="title-mobile">Declined:</div>
                        {this.renderPlayerBlock(this.state.declinedUsers)}
                    </div>}
                </div>
            );
    }

    private selectPlayers(list: any[]) {
        const { user, users } = this.props;
        const invitedUsers = this.save.invitedUsers.filter(u => list.some(i => i.key === u.id));
        const usersToInvite: StM.IPublicUserStoreState[] = list.reduce((result, item) => {
            if(!invitedUsers.some(i => i.id === item.key)) {
                const userToInvite = users.find(u => u.id === item.key);
                if (userToInvite) {
                    userToInvite.invitedBy = user.id;
                    result.push(userToInvite);
                }
            }
            return result;
        }, []);
        invitedUsers.push(...usersToInvite);
        this.save.invitedUsers = invitedUsers;
        this.setState({ ...this.state, invitedUsers });
    }

    private renderCoach(session: StM.SessionStoreState) {
        if (!session.trainerId) return null;
        
        let trainer = session.trainer;
        
        if(!trainer){
            trainer = new StM.CoachStoreState();
            trainer.displayName = "Suspended"
        }

        let image = trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return !this.state.shareBoard && (
            <div className="coach-wrapper-mobile flex-center-mobile">
                <img className="coach-photo-mobile" src={imageUrl} />
                <span className="coach-name-mobile text-ellipsis">{trainer.displayName}</span>
            </div>
        )
    }

    private renderCourts(session: StM.SessionStoreState) {
        return <div className="court-number-mobile">{utils.getReadableSessionCourtsTitle(session)}</div>;
    }

    private handlerRemovePlayer(id: string) {
        const invitedUsers = this.save.invitedUsers.filter(u => u.id !== id);
        this.save.invitedUsers = invitedUsers;
        this.setState({ ...this.state, invitedUsers });
    }

    onPrivate(e: any) {
        if (e) e.preventDefault();
        this.setState({ ...this.state, isShared: false });
        this.forceUpdate();
    }
    offPrivate(e: any) {
        if (e) e.preventDefault();
        this.setState({ ...this.state, isShared: true });
        this.forceUpdate();
    }

    handlePrivate() {
        this.setState({ ...this.state, isShared: !this.state.isShared });
        this.forceUpdate();
    }

    private selectSearchOptions(item: any, field: string) {
        let q = this.save.playerQualification;
        let s = this.save.seeking;
        if (field == 'sex') {
            q.sex = item.value != '' ?
                item.value :
                "Male, Female";
        }
        if (field == 'skill') {
            q.skill = item.value != '' ?
                item.value :
                "Beginner, Intermediate, Advanced";
        }
        if (field == 'type') {
            if (item.key != 4) s.description = '';
            s.type = item.value;
        }
        this.forceUpdate();
    }

    private typePlayHandler(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.save.seeking.description = e.target.value;
        this.forceUpdate();
    }

    private onPrivat(e: any) {
        if (e) e.preventDefault();
        this.setState({ ...this.state, isShared: false });
    }

    private offPrivat(e: any) {
        if (e) e.preventDefault();
        this.setState({ ...this.state, isShared: true });
    }

    private filterCurrentUser(array: Array<any>) {
        if (this.props.user.id) {
            array = array.filter(item => item.id != this.props.user.id);
        }
        return array;
    }

    private disablePlayerSelection(count: number) {
        if (!this.props.session || !this.props.session.id) return false;
        return this.props.session.isPaidByOwner ? count > this.props.session.maxUserCount : count < this.props.session.maxUserCount;
    }

    setOpacity() {
        var blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
        var tabContent: any = document.getElementsByClassName('tab-content')[0];
        tabContent.style.opacity = '1';
    }

    openInvitationDialog(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setState({ ...this.state, isInvitationDialogOpened: true });
    }

    closeInvitationDialog() {
        this.setState({ ...this.state, isInvitationDialogOpened: false });
    }

    openSessionBoardDialog(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setState({ ...this.state, isOpenSessionBoardDialogOpened: true });
    }

    closeOpenSessionBoardDialog() {
        this.setState({ ...this.state, isOpenSessionBoardDialogOpened: false });
    }

    private renderClinicTitle(session: StM.ISessionStoreState) {
        if (session.type == StM.SessionType.Clinic && session.title) {
            return <div className="clinic-title">{session.title}</div>
        } else {
            return null;
        }
    }

    isTouchDetect(): boolean {

        return 'ontouchstart' in window ||
            "ontouchstart" in document.documentElement// works on most browsers 
        //   || 'onmsgesturechange' in window; // works on ie10
    }

    private renderShareLinkTab() {

        let setHeight = (elem: any) => {
            var blockInviteWrapper: any = document.getElementsByClassName('invite-wrapper')[0];
            blockInviteWrapper.style.height = 350 + 'px';

            var tabContent: any = document.getElementsByClassName('tab-content')[0];

            if (!isOpenShareList) {
                if (!firstOpen) {
                    tabContent.style.opacity = '1';
                    // firstOpen = true;
                } else {
                    tabContent.style.opacity = '0';
                    setTimeout(() => this.setOpacity(), 300);
                    isOpen = false;
                    isOpenPlayerList = false;
                    isOpenShareList = true;
                    // firstOpen = false;
                }
                firstOpen = true;
            }
        }

        const inviteLink = this.props.getSessionInviteLink(this.props.inviteToken, this.save);
        const copyClass = ClassNames("share-btn", { "share-btn-touch": this.isTouchDetect() });
        return (
            <div ref={(elem) => setHeight(elem)}>
                <div className="share-url">
                    <div className="title">URL</div>
                    <div className="link-wrapper">
                        <input type="text" ref={(elem) => { this.shareLinkDOM = elem; if (elem) { elem.select() } }} value={inviteLink} onChange={(e) => false} />
                        <a className={copyClass} href="#" onClick={(e) => this.shareClickHandler(e)}>Copy</a>
                    </div>
                    <div className="share-btn-wrapper">
                        <a className="share-btn icon-btn mail" href="#" onClick={(e) => this.onSendLinkByEmailClick(e)}>Send link by email</a>
                        <a className="share-btn icon-btn facebook" href="#" onClick={(e) => this.onPostOnFacebookClick(e)}>Post on Facebook</a>
                    </div>
                </div>
            </div>
        );
    }

    private renderDropOut(session: StM.SessionStoreState) {
        const isOwner = this.sessionAvailabilityPolicy.getIsOwner();
        const isGroupSession = this.sessionAvailabilityPolicy.getIsGroupSession();
        const isBought = this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isCanCancel = this.sessionAvailabilityPolicy.getIsCanCancelSession();
        const isCanDropOut = this.sessionAvailabilityPolicy.getIsCanDropOut();
        const isOwnerGroupMember = this.sessionAvailabilityPolicy.getIsOwnerGroupMember();
        const isBtnShow = isOwner || (!isGroupSession && isBought) || (isGroupSession && isOwnerGroupMember);
        const isLesson = session.type === StM.SessionType.Private;
        const isPlay = session.type === StM.SessionType.Play;
        const isGroupCanCancelSession = isGroupSession && (session.isPaidByOwner || isPlay
            || !session.bookings.some(b => utils.isActiveBooking(session, b) && !this.groupInfoPolicy.getIsMember(b.userId)));

        if (!isBtnShow) return null;
        return isGroupSession || (isOwner && (!isLesson || (isLesson && (session.isPaidByOwner || this.sessionInfoPolicy.getPlayers().length <= 1)))) 
            ? ((!isGroupSession || isGroupCanCancelSession) && <div><button className="btn btn-cancel btn-black btn-mobile" onClick={(e) => this.cancelClick(e)} disabled={!isCanCancel}>Cancel session</button></div>) 
            : (<button className="btn btn-drop btn-mobile btn-black" onClick={(e) => this.dropOutClick(e)} disabled={!isCanDropOut}>Drop out</button>);
    }

    private renderInvitedFooter(session: StM.SessionStoreState) {
        const isInvited = this.getIsInvited();
        const isFuture = this.sessionAvailabilityPolicy.getIsFuture();
        const players = this.sessionInfoPolicy.getPlayers();
        const isClosed = session.status === StM.SessionStatus.Closed;
        const isLateCancelled = _.some(session.bookings, (b) => b.userId == this.props.user.id && (b.status == StM.BookingStatus.LateCancel || b.status == StM.BookingStatus.LateCancelPayFail));
        const showSaveBtn = this.state.payCreditsSelectionUpdated;

        return (
            <>
                <SessionPrices session={this.getSessionFromState()} />
                {(!isClosed && isFuture) && (
                    <div className="btns-wrapper">
                        {session.basketId || players.length >= session.maxUserCount ? (
                            <>
                                {session.basketId && <button className="btn btn-delete btn-mobile btn-black" onClick={e => this.onDeleteClick(e)}>Delete</button>}
                                {showSaveBtn && <button type='button' className='btn btn-mobile btn-black' onClick={e => this.onSubmitClick(e)}>Save</button>}
                                {!showSaveBtn && <button type='button' className="btn btn-mobile btn-black" onClick={e => this.closeClick(e)}>Close</button>}
                            </>
                        ) : !isLateCancelled ? isInvited ? (
                            <>
                                <button className="btn btn-decline btn-mobile btn-black" onClick={e => this.rejectInvitationClick(e)}>I can't</button>
                                <button className="btn btn-join btn-mobile btn-red" onClick={e => this.joinClick(e)}>Join</button>
                            </>
                        ) : (<button className="btn btn-join btn-mobile btn-red" onClick={e => this.joinClick(e)}>JOIN</button>) : ''
                        }
                    </div>
                )}
            </>
        );
    }

    private renderPlayerFooter(session: StM.SessionStoreState, user: StM.IUserStoreState) {
        const isCanEditOwner = this.sessionAvailabilityPolicy.getIsOwner() || this.sessionAvailabilityPolicy.getIsBoughtUser();
        const isFuture = this.sessionAvailabilityPolicy.getIsFuture();
        const isClosed = session.status === StM.SessionStatus.Closed;
        return (
            <>
                <SessionPrices className='session-prices-wrapper' session={this.getSessionFromState()} />
                {(isFuture && !isClosed) && (
                    <div className="btns-wrapper player-footer-mobile">
                        {(this.state.shareBoard || isCanEditOwner) && (
                            <button className="btn btn-save btn-mobile btn-red" onClick={(e) => this.onSubmitClick(e)}>Save</button>
                        )}
                        {!this.state.shareBoard && (this.renderDropOut(session))}
                    </div>
                )}
            </>
        )
    }

    private getIsNoBasketSessionsInTimeCurrentTime(): boolean {
        let inCurentTimeSession = [];
        if (this.props.session) {
            let date = this.props.session.startDateTime.clone().startOf('day');
            let start = moment.duration(this.props.session.startDateTime.format('H:mm'));
            let court = this.props.session.court;

            inCurentTimeSession = _.filter(this.props.basketSessions, (session: StM.ISessionStoreState) => {
                const sessionStartTime = moment.duration(session.startDateTime.format('H:mm'));
                const isCurrentDate = session.startDateTime.clone().startOf('day').valueOf() === date.clone().startOf('day').valueOf();
                const isCurrentTime = sessionStartTime.asHours() === start.asHours()
                return isCurrentDate && isCurrentTime;
            });
        }
        return !inCurentTimeSession.length;
    }

    private shareClickHandler(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.shareLinkDOM) {
            this.shareLinkDOM.select();
            document.execCommand('copy');
        }
    }

    private onDeleteClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.removeBasketSession(this.props.session);
        this.reset();
        this.props.closeSessionInfoDialog();
    }

    private onSendLinkByEmailClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        const sharedLink = this.props.getSessionInviteLink(this.props.inviteToken, this.save);
        const mailToLink = utils.getMailToLink('', '', StM.Strings.InvitationEmailSubject, sharedLink);
        window.location.href = mailToLink;
    }

    private onPostOnFacebookClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (e) { 
          e.preventDefault(); 
          e.stopPropagation(); 
        }

        const winWidth = 520;
        const winHeight = 350;
        const sharedLink = this.props.getFBSessionInviteLink(this.props.inviteToken);
        const winTop = (window.screen.height / 2) - (winHeight / 2);
        const winLeft = (window.screen.width / 2) - (winWidth / 2);
        
        window.open(
          utils.getPostOnFacebookLink(sharedLink),
          'fbSharer',
          `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`
        );
      }

    private handleServicesChange = (services: StM.IAddonDefinitionStoreState[]) => {
        this.setState({ ...this.state, services });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.sessionInfo;
    let goods = state.basket.goods;
    let session: StM.ISessionStoreState = null;
    let basketCopy = null;
    let originCopy = null;
    const agreeWithCancelationFee = false;

    if (dialog.isOpened) {
        let sessions = utils.getPageSessions(state);
        originCopy = state.dialogs.sessionInfo.session;
        const checkIsCourse = (basketItem: StM.ISessionStoreState, session: StM.ISessionStoreState): boolean => {
            const isCourse = !!session && !!session.series && session.series.isCourse && !!basketItem.series && basketItem.series.isCourse;
            return isCourse && basketItem.series.id === session.series.id;
        };

        if (dialog.from == StM.SessionInfoFromTypes.basket) {
            session = _.find(sessions, { basketId: +dialog.id }) || _.find(sessions, { id: +dialog.id });
            basketCopy = originCopy = session;
        }
        else {
            originCopy = state.dialogs.sessionInfo.session;
            basketCopy = goods.find(item => checkIsCourse(item, originCopy) || item.id === +dialog.id);
            if (basketCopy) originCopy = basketCopy;
        }
        if (session && session.basketId) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.basketId === session.basketId; });
            if (!fromBasket) session.basketId = null;

        }
        if (session && session.id) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.id === session.id; });
            if (fromBasket) session.basketId = fromBasket.basketId;
        }
    }

    session = basketCopy || originCopy;
    const sessionInfoPolicy = new PolM.SessionInfoPolicy(session, state.user);
    const notification = sessionInfoPolicy.getSessionNotification();
    return {
        params: ownProps && ownProps.params ? ownProps.params : null,
        match: state.app.match,
        isShown: state.dialogs.sessionInfo.isOpened,
        isAuthorized: state.app.isAuthorized,
        from: dialog.from,
        id: dialog.id,
        inviteToken: dialog.inviteToken,
        session,
        notification,
        user: state.user,
        club: state.club,
        users: state.activeUsers,
        addons: state.addons,
        maxPlayerCount: session ? PolM.SessionInfoPolicy.getMaxPlayerCount(session.type) : 0,
        basketSessions: state.basket.goods,
        basketCopy,
        originCopy,
        agreeWithCancelationFee: agreeWithCancelationFee
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeSessionInfoDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SessionInfo)),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        editBasketItem: (item: StM.ISessionStoreState) => dispatch(ActM.BasketActions.edit(item)),
        addSessionToBasket: (item: StM.SessionStoreState) => { dispatch(ActM.BasketActions.add(item)); dispatch(ActM.DialogActions.open(StM.DialogNames.SessionCreateSuccess, { isExist: true })) },
        addSessionToBasketSilently: (item: StM.SessionStoreState) => { dispatch(ActM.BasketActions.add(item)); },
        rejectInvitation: (sessionId: number, params: any) => dispatch(ActM.SessionInfoDialogActions.rejectInvitation(sessionId, params)),
        saveSession: (session: StM.ISessionStoreState) => dispatch(ActM.SessionActions.save(session)),
        saveInvitedUsers: (users: Array<StM.IPublicUserStoreState>, sessionId: number, token: string) => dispatch(ActM.SessionActions.saveInvitedUsers(users, sessionId, token)),
        dropOutSession: (session: StM.ISessionStoreState, user: StM.IUserStoreState, agreeWithCancelationFee: boolean) => dispatch(ActM.SessionInfoDialogActions.dropOut(session, agreeWithCancelationFee)),
        cancelSession: (session: StM.ISessionStoreState, agreeWithCancelationFee: boolean) => dispatch(ActM.SessionInfoDialogActions.cancel(session, agreeWithCancelationFee)),
        getSessionById: (id: number) => dispatch(ActM.SessionInfoDialogActions.getSessionById(id)),
        getSessionByInviteToken: (inviteToken: string) => dispatch(ActM.SessionInfoDialogActions.getSessionByInviteToken(inviteToken)),
        getSessionInviteToken: (sessionId: number) => dispatch(ActM.SessionInfoDialogActions.getSessionInviteToken(sessionId)),
        getSessionInviteLink: (inviteToken: string, session: StM.ISessionStoreState) => ActM.SessionInfoDialogActions.getSessionInviteLink(inviteToken, session),
        getFBSessionInviteLink: (inviteToken: string) => ActM.SessionInfoDialogActions.getFBSessionInviteLink(inviteToken),
        acceptSessionInviteByInviteToken: (inviteToken: string) => dispatch(ActM.SessionInfoDialogActions.acceptSessionInviteByInviteToken(inviteToken)),
        rejectSessionInviteByInviteToken: (inviteToken: string) => dispatch(ActM.SessionInfoDialogActions.rejectSessionInviteByInviteToken(inviteToken)),
        updateCurrentPageSessions: (params: any) => dispatch(ActM.SessionActions.updateForCurrentPage(params)),
        sendNotification: (notification: StM.INotificationStoreState) => dispatch(ActM.NotificationActions.create(notification)),
        showAlert: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: msgKey, messageType: StM.MessageTypes.Error })),
        showAlertCancelSession: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.CancelSession, messageType: StM.MessageTypes.Success })),
        showAlertDropOutSession: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.DropOutSession, messageType: StM.MessageTypes.Success })),
        showSuccessInvitationAcceptPrompt: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.DecisionConfirmation, messageType: StM.MessageTypes.Success})),
        showErrorInvitationAcceptPrompt: (message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError, messageType: StM.MessageTypes.Error, message })),
        showRejectInvitationPrompt: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.DecisionConfirmation, messageType: StM.MessageTypes.Error})),
        removeBasketSession: (item: StM.SessionStoreState) => dispatch(ActM.BasketActions.removeById(item.basketId)),
        removeNotificationsBySession: (id: number) => dispatch(ActM.NotificationActions.removeBySessionId(id)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        getSessionVideoLink: (token: string) => ActM.SessionInfoDialogActions.getSessionVideoLink(token),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true)),
        showCancelConfirmation: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => dispatch(ActM.DialogActions.open(StM.DialogNames.CancelConfirmation, { msgKey, messageType, message, acceptButtonText, rejectButtonText, yesCallback })),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionInfoDialogMobile);
