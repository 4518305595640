import './dialogs.mobile.scss';
import './subscriptionCheckoutDialog.mobile.scss';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import { ICoachFeeTierStoreState, IPackageSessionTypeStoreState } from '@models/store';
import * as ActM from '../../actions';
import { Modal } from '../../components';
import classNames from 'classnames';
import moment from 'moment';
import Strings from "../../models/store/strings";

import * as SrvM from '../../services';
import SubscriptionOfferInfo from '../../components/pages/subscriptionOffer/subscriptionOfferInfo';
import { ISubscriptionCreditsDefinition } from '../pages/packages/subscriptionsList';

const utils = new SrvM.Utils();

interface ISubscriptionCheckoutDialogProps {
    match: any;
    isShown: boolean;
    user: StM.UserStoreState;
    club: StM.ClubStoreState;
    isAuthorized: boolean;
    subscriptionDefinition: StM.ISubscriptionOfferStateModel;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    sessionTypes: IPackageSessionTypeStoreState[];
    coachFeeTiers: ICoachFeeTierStoreState[];

    openAuthDialog: (url: string) => Promise<any>;
    openBillingInfoDialog: () => Promise<any>;
    checkout: (definition: StM.ISubscriptionOfferStateModel, params: any) => Promise<any>;
    closeDialog: () => void;
    successAlert: () => Promise<any>;
    openCreditTermsDialog: () => Promise<any>;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
}

interface ISubscriptionCheckoutDialogState {
    validationError: string;
    termsAccepted?: boolean;
}

class SubscriptionCheckoutDialog extends React.Component<ISubscriptionCheckoutDialogProps, ISubscriptionCheckoutDialogState> {

    termsAccepted: boolean;

    constructor(props: ISubscriptionCheckoutDialogProps) {
        super(props);
        this.state = { validationError: null };
    }

    componentDidUpdate(prevProps) {
        const isShowChanged = !prevProps.isShown && this.props.isShown;
        if (isShowChanged) {
            this.setState({ validationError: null, termsAccepted: false });
        }
    }

    render() {
        if (this.props.isShown) {
            const subscription = this.props.subscriptionDefinition;
            let price = utils.handleMinPrice(this.props.subscriptionDefinition.price);
            const priceWoSalesTax = price;
            let tax = 0;
            const startBillingDate = moment().format('MMM DD, YYYY');

            const currentSessionTypes =
                subscription.creditsDefinitions
                    .map((ctd) => this.props.sessionTypes.find(st => st.id === ctd.packageSessionTypeId))
                    .map(st => st.sessionType.toLowerCase());

            const applySalesTax = _.some(this.props.club.salesTaxes, (y) => currentSessionTypes.includes(y.subject.toLowerCase()));

            if (applySalesTax) {
                tax = price * this.props.club.salesTaxRate / 100;
                price += tax;
            }
            const totalCssClasses = classNames({
                'subscription-price-wrapper': true,
                'price-total': !!applySalesTax
            });

            const creditsDefinitions = this.getSubscriptionCreditsDefinitions(subscription);

            return (
                <Modal classes="your-estimated-charges-modal-mobile checkout-packages-modal-mobile" closeHandler={this.props.closeDialog}
                    dialogName={StM.DialogNames.SubscriptionPurchase}>
                    <div className="modal-dialog-header">
                        <div className="title checkout">Complete Purchase</div>
                    </div>
                    <div className="modal-dialog-body-block">
                        {this.state.validationError && (
                            <div className="error-message-wrapper">
                                <span className="error-message-title">{this.state.validationError}</span>
                            </div>
                        )}
                        <SubscriptionOfferInfo
                            id={subscription.id}
                            title={subscription.title}
                            description={subscription.description}
                            billingPeriod={subscription.billingPeriod}
                            price={subscription.price}
                            creditsDefinitions={creditsDefinitions}
                            customSessionTypes={this.props.customSessionTypes}
                            sessionTypes={this.props.sessionTypes || []}
                            coachFeeTiers={this.props.coachFeeTiers || []}
                        />
                        {applySalesTax && <div className="subscription-price-wrapper">
                            <div className="price-title">
                            Price
                            </div>
                            <div className="price-count">
                            ${utils.formatStringPrice(priceWoSalesTax.toString())}
                            </div>
                        </div>}
                        {applySalesTax && <div className="subscription-price-wrapper">
                            <div className="price-title">
                                {this.props.club.salesTaxTitle} (${this.props.club.salesTaxRate}%)
                            </div>
                            <div className="price-count">
                            ${utils.formatStringPrice(tax.toString())}
                            </div>
                        </div>}
                        <div className={totalCssClasses}>
                            <div className="price-title">
                                {applySalesTax ? 'Total' : 'Price'}
                            </div>
                            <div className="price-count">
                                ${utils.formatStringPrice(price.toString())}
                            </div>
                        </div>
                        <div className="divider-line"/>

                        <div className="subsciprion-checkbox-wrapper">
                            <p>
                                <span className='bold-text'>Billing will start on {startBillingDate} and will renew automatically every month.</span><br />
                                Manage your subscription anytime by <a href={this.props.club.url + "/user/club-credits"}
                                className="subsciprion-terms-link"
                                >clicking here</a>.
                            </p>
                            <p>
                                You will be charged with the credit card we have on file. 
                                If you wish to update your billing 
                                information <a href={this.props.club.url + "/user/profile#dialogs=billinginfo/"} className="subsciprion-terms-link">
                                    click here
                                </a>.
                            </p>
                            <p>
                                By continuing, you agree to the Squash On Fire <a href="#"
                                className="subsciprion-terms-link"
                                onClick={(e) => this.onCreditTermsClick(e)}>
                                Terms and Conditions</a>.
                            </p>
                        </div>
                    </div>
                    <div className="modal-dialog-footer">
                        <div className="btns-wrapper">
                            <button className="btn-mobile btn-checkout btn-red" onClick={(e) => this.checkoutClick(e)}>Buy Subscription</button>
                            <button className="btn-mobile btn-black" onClick={(e) => this.props.closeDialog()}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            );
        }
        return null;
    }

    closeClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.closeDialog();
    }

    onCreditTermsClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.openCreditTermsDialog();
    }

    checkoutClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        
        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club)
            );

            return;
        }


        this.state = { ...this.state, validationError: null };

        if (!this.props.isAuthorized) {
            const thunk = new SrvM.RouteDialogService();
            const hash = thunk.getDialogHash(StM.DialogNames.SubscriptionPurchase, { id: this.props.subscriptionDefinition.id });
            const url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else if (!this.props.user.paymentProfileId ||
                   this.props.user.paymentProfileStatus !== StM.PaymentProfileStatus.Active) {
            this.props.openBillingInfoDialog();
        } else {
            this.props.closeDialog();
            this.props.checkout(this.props.subscriptionDefinition, this.props.match.params);
        }
    }

    private getSubscriptionCreditsDefinitions(subscriptionOfferStateModel: StM.ISubscriptionOfferStateModel): Array<ISubscriptionCreditsDefinition> {
        return subscriptionOfferStateModel.creditsDefinitions.map((c: IRecurrentPackageCreditsDefinitionDto) => {
            return {
                alias: c.typeAlias,
                color: c.color,
                credits: c.credits,
                description: c.description,
                packageSessionTypeId: c.packageSessionTypeId,
                sortOrder: c.sortOrder,
                title: c.title,
            }
        });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        match: state.app.match
        , isShown: state.dialogs.subscriptionPurchase.isOpened
        , isAuthorized: state.app.isAuthorized
        , user: state.user
        , club: state.club
        , subscriptionDefinition: state.dialogs.subscriptionPurchase.subscriptionDefinition ||
                                  (state.dialogs.subscriptionPurchase.subscriptionDefinitionId
                                      ? _.find(state.subscriptionOffers.definitions, (x) => state.dialogs.subscriptionPurchase.subscriptionDefinitionId === x.id)
                                      : _.first(state.subscriptionOffers.definitions))
        , customSessionTypes: state.customSessions
        , sessionTypes: state.subscriptionOffers.types
        , coachFeeTiers: state.coachFeeTiers
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SubscriptionPurchase)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, {
            tab: StM.AuthDialogTabs.SignIn,
            returnUrl: url
        })),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        checkout: (definition: StM.ISubscriptionOfferStateModel, params: any) => dispatch(ActM.SubscriptionPurchaseDialogActions.checkout(definition, params)),
        successAlert: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
            msgKey: StM.MessagesKey.PackageCheckoutSuccess,
            messageType: StM.MessageTypes.Success
        })),
        openCreditTermsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.CreditTerms)),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCheckoutDialog);
