import './dialogs.scss';
import './packageCheckoutDialog.scss';
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import * as ActM from '../../actions';
import { Modal } from '../../components';
import PackageInfo, { IPackageInfoCreditsDefinition } from '../../components/pages/packages/packageInfo';
import classNames from 'classnames';
import Strings from "../../models/store/strings";

import * as SrvM from '../../services';

const utils = new SrvM.Utils();

interface IPackageCheckoutDialogProps {
    match: any;
    isShown: boolean;
    user: StM.UserStoreState;
    club: StM.ClubStoreState;
    isAuthorized: boolean;
    packageDefinition: StM.IPackageDefinitionStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;

    openAuthDialog: (url: string) => Promise<any>;
    openBillingInfoDialog: () => Promise<any>;
    checkout: (definition: StM.IPackageDefinitionStoreState, params: any) => Promise<any>;
    closeDialog: () => void;
    successAlert: () => Promise<any>;
    openCreditTermsDialog: () => Promise<any>;
    addToBasket: (item: StM.IPackageDefinitionStoreState) => void;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
}

interface IPackageCheckoutDialogState {
    validationError: string;
    termsAccepted?: boolean;
}

class PackageCheckoutDialog extends React.Component<IPackageCheckoutDialogProps, IPackageCheckoutDialogState> {
    termsAccepted: boolean;

    constructor(props: IPackageCheckoutDialogProps) {
        super(props);
        this.state = {
            validationError: null,
            termsAccepted: false,
        };
    }

    componentDidUpdate(prevProps: IPackageCheckoutDialogProps) {
        if (!prevProps.isShown && this.props.isShown) {
            this.setState({ validationError: null, termsAccepted: false });
        }
    }

    render() {
        if (this.props.isShown) {
            const pack = this.props.packageDefinition;
            let price = utils.handleMinPrice(this.props.packageDefinition.price);
            const priceWoSalesTax = price;
            const total = 0;
            let tax = 0;

            const applySalesTax = _.some(pack.creditsDefinitions,
                (x) => _.some(this.props.club.salesTaxes, 
                    (y) => y.subject.toLowerCase() == x.packageSessionType.sessionType.toLowerCase()));

            if (applySalesTax) {
                tax = price * this.props.club.salesTaxRate / 100;
                price += tax;
            }

            const totalClasses = classNames({
                'price-wrapper': true,
                'price-total': !!applySalesTax
            });

            const expirationDate = moment().clone().add(pack.expirationTimeSpan.asDays(), 'days');
            const creditsDefinitions = this.getPackageCreditsDefinitions(pack);

            return (
                <Modal classes="your-estimated-charges-modal checkout-packages-modal" closeHandler={this.props.closeDialog}
                    dialogName={StM.DialogNames.PackagePurchase}>
                    <div className="modal-dialog">
                        <div className="modal-dialog-header">
                            <span className="close-dialog" onClick={(e: any) => this.closeClick(e)}/>
                            <div className="title checkout">Checkout Package</div>
                        </div>
                        <div className="modal-dialog-body">
                            {this.state.validationError && (
                                <div className="error-message-wrapper">
                                    <span className="error-message-title">{this.state.validationError}</span>
                                </div>
                            )}
                            <PackageInfo
                                id={pack.id}
                                title={pack.title}
                                description={pack.description}
                                expirationDate={expirationDate}
                                coachFeeTier={pack.coachFeeTier}
                                price={pack.price}
                                creditsDefinitions={creditsDefinitions}
                                customSessionTypes={this.props.customSessionTypes}
                            />
                            {applySalesTax && <div className="price-wrapper">
                              <div className="price-title">
                                Price
                              </div>
                              <div className="price-count">
                                ${utils.formatStringPrice(priceWoSalesTax.toString())}
                              </div>
                            </div>}
                            {applySalesTax && <div className="price-wrapper">
                              <div className="price-title">
                                  {this.props.club.salesTaxTitle} (${this.props.club.salesTaxRate}%)
                              </div>
                              <div className="price-count">
                                ${utils.formatStringPrice(tax.toString())}
                              </div>
                            </div>}
                            <div className={totalClasses}>
                                <div className="price-title">
                                    {applySalesTax ? 'Total' : 'Price'}
                                </div>
                                <div className="price-count">
                                    ${utils.formatStringPrice(price.toString())}
                                </div>
                            </div>
                            <div className="divider-line"/>
                            <div className="btns-wrapper modal-dialog-body-content">
                                <div className="checkbox-terms-wrapper">
                                    <div className="checkbox-wrapper">
                                        <label className="terms-label">
                                            <a href="#" className="terms-link" onClick={(e) => this.onCreditTermsClick(e)}>Credit Terms</a>
                                        </label>
                                    </div>
                                </div>
                                <div className="btn-footer-wrapper">
                                    <button className="btn-checkout btn-red" onClick={(e) => this.checkoutClick(e)}>Add To Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        }
        return null;
    }

    closeClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.closeDialog();
    }

    onCreditTermsClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.openCreditTermsDialog();
    }

    checkoutClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club)
            );

            return;
        }

        this.state = { ...this.state, validationError: null };

        if (!this.props.isAuthorized) {
            const thunk = new SrvM.RouteDialogService();
            const hash = thunk.getDialogHash(StM.DialogNames.PackagePurchase, { packageDefinitionId: this.props.packageDefinition.id });
            const url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else if (!this.props.user.paymentProfileId ||
                   this.props.user.paymentProfileStatus !== StM.PaymentProfileStatus.Active) {
            this.props.openBillingInfoDialog();
        } else {
            this.props.closeDialog();
            this.props.addToBasket(this.props.packageDefinition);
            // this.props.successAlert();}).catch((error: string) => this.setState({...this.state, validationError: error}));
        }
    }

    private getPackageCreditsDefinitions(packageDef: StM.IPackageDefinitionStoreState): Array<IPackageInfoCreditsDefinition> {
        return packageDef.creditsDefinitions.map(c => {
            return {
                id: c.id,
                credits: c.credits,
                title: c.title,
                description: c.description,
                sortOrder: c.sortOrder,
                sessionType: c.packageSessionType.sessionType,
                alias: c.typeAlias
            }
        });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        match: state.app.match,
        isShown: state.dialogs.packagePurchase.isOpened,
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        club: state.club,
        packageDefinition: state.dialogs.packagePurchase.packageDefinition ||
            (state.dialogs.packagePurchase.packageDefinitionId
                ? _.find(state.packages.definitions, (x) => state.dialogs.packagePurchase.packageDefinitionId == x.id)
                : _.first(state.packages.definitions)),
        customSessionTypes: state.customSessions
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.PackagePurchase)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, {
            tab: StM.AuthDialogTabs.SignIn,
            returnUrl: url
        })),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        checkout: (definition: StM.IPackageDefinitionStoreState, params: any) => dispatch(ActM.PackagePurchaseDialogActions.checkout(definition, params)),
        successAlert: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
            msgKey: StM.MessagesKey.PackageCheckoutSuccess,
            messageType: StM.MessageTypes.Success
        })),
        openCreditTermsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.CreditTerms)),
        addToBasket: (item: StM.IPackageDefinitionStoreState) => {
            dispatch(ActM.DialogActions.open(StM.DialogNames.PackageAdded));
            dispatch(ActM.BasketActions.add(item));
        },
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageCheckoutDialog);
